<template>
    <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel"
        aria-hidden="true" @hidden="handleModalHidden">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="notificationModalLabel">Notification</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div v-if="role == 'super-admin'" class="mb-3">
                        <div class="d-flex flex-row align-items-center mb-2">
                            <div>
                                <label class="me-3 mb-0">Select District's</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <input type="checkbox" :checked="selectedDIds?.length == districts.length" 
                                    @change="toggleSelectAll" id="selectAll" class="form-check-input me-2"
                                    style="margin-top: 0px;">
                                <label for="selectAll" class="mb-0">
                                    {{ (selectedDIds && selectedDIds.length === districts.length) ? 'Unselect All' :
                                    'Select All' }}
                                </label>
                            </div>
                        </div>
                        <div>
                            <multiselect class="mb-3" v-model="selectedDIds" :options="districts" :multiple="true"
                                :close-on-select="false" placeholder="Select District's" label="name" track-by="id">
                            </multiselect>
                        </div>
                    </div>

                    <div v-if="role == 'super-admin'" class="mb-3">
                        <div class="d-flex flex-row align-items-center justify-content-start mb-2">
                            <label class="me-3 mb-0">Select Sub Divisions's</label>
                            <input type="checkbox" :checked="selectedTIds?.length == tehsils.length"
                                style="margin-top: 0px;" @change="toggleSelectAllTehsils" id="selectAllTehsils"
                                class="form-check-input">
                            <label for="selectAllTehsils" class="ms-2 mb-0">
                                {{ (selectedTIds && selectedTIds.length === tehsils.length) ? 'Unselect All' : 'Select All' }}
                            </label>
                        </div>
                        <div>
                            <multiselect class="mb-3" v-model="selectedTIds" :options="tehsils" :multiple="true"
                                :close-on-select="false" placeholder="Select Sub Division's" label="name" track-by="id">
                            </multiselect>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Gender</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="genderMale" name="gender" v-model="gender"
                                value="Male" />
                            <label class="form-check-label" for="genderMale">Male</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="genderFemale" name="gender"
                                v-model="gender" value="Female" />
                            <label class="form-check-label" for="genderFemale">Female</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="genderAll" name="gender" v-model="gender"
                                value="All" />
                            <label class="form-check-label" for="genderAll">All</label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="ageRange" class="form-label">Age Range</label>
                        <div class="d-flex">
                            <input type="number" class="form-control me-2" placeholder="From" min="0" required
                                v-model="ageFrom" />
                            <input type="number" class="form-control" placeholder="To" required v-model="ageTo" />
                        </div>
                        <div v-if="ageRangeError" class="text-danger mt-1">{{ ageRangeError }}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="handleSave">Save</button>
                    <button type="button" id="closeModal" class="btn btn-secondary"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ageFrom: 0,
            ageTo: 100,
            gender: "",
            ageRangeError: '',
            role: localStorage.getItem('role'),
            districts: [],
            selectedDIds: [],
            selectedTIds: [],
            tehsils: [],
        };
    },

    mounted() {
        this.fetchDistricts();
        this.fetchSubDivisions();
    },

    methods: {
        handleSave() {
            this.validateAgeRange();
            if (!this.ageRangeError) {
                const filterData = {
                    gender: this.gender,
                    ageFrom: this.ageFrom,
                    ageTo: this.ageTo
                };
                const btn = document.getElementById("closeModal");
                btn.click();
                this.$emit('save', filterData);
            }
        },
        validateAgeRange() {
            if (this.ageFrom > this.ageTo) {
                this.ageRangeError = 'The "From" age must be less than or equal to the "To" age.';
            } else {
                this.ageRangeError = '';
            }
        },
        fetchDistricts(search = "") {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll", { search, paginate: false })
                .then((response) => {
                    this.districts = response.data;
                    console.log("districts", this.districts);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchSubDivisions(search = "") {
            this.loading = true;
            this.$store
                .dispatch("tehsil/getAll", { search, paginate: false })
                .then((response) => {
                    this.tehsils = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        toggleSelectAll(event) {
            if (event.target.checked) {
                this.selectedDIds = this.districts;

            } else {
                this.selectedDIds = [];
            }
        },
        toggleSelectAllTehsils(event) {
            if (event.target.checked) {
                this.selectedTIds = this.tehsils;

            } else {
                this.selectedTIds = [];
            }
        }
    }
}
</script>