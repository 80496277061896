<template>
    <div class="col-xxl-4 col-md-6 box-col-6">
        <div class="card">
            <div class="card-header card-no-border">
                <div class="header-top">
                    <h5 class="m-0 mb-2">poll</h5>
                    <div class="card-header-right-icon">

                    </div>
                </div>
            </div>
            <div class="card-body pt-0" v-if="isDataLoaded && data.poll">
                {{ data?.poll?.title }}
                <div class="schedule-container">
                    <!-- <apexchart height="305" type="bar" :options="apexDashboard.options3" :series="apexDashboard.series3">
                    </apexchart> -->

                    <apexchart height="355" type="bar" :options="options5" :series="series5">
                    </apexchart>
                </div>
            </div>
            <div v-else>
                <div class="schedule-container">
                    <p class="text-center">No poll added yet.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown2 from "../base/dropdown/DropDown2.vue";
import {
    apexDashboard
} from "../../../data/comon";
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    components: {
        DropDown2
    },
    watch: {
        data: {
            handler(newVal) {
                if (newVal && newVal.poll && newVal.poll.question) {
                    let result = [];
                    newVal.poll.question.survey_options.forEach((item) => {
                        let obj = {
                            x: item.option,
                            y: [item.user_submit_count]
                        };
                        result.push(obj);
                    });
                    this.series5[0].data = result;
                    this.isDataLoaded = true;
                }
            },
            deep: true
        },
    },
    data() {
        return {
            apexDashboard: apexDashboard,
            isDataLoaded: false,
            options5: {
                chart: {
                    type: "bar",
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: true,
                },

            },

            series5: [
                {
                    data: [
                        {
                            x: "Disaster",
                            y: [8],
                        },
                        {
                            x: "Education",
                            y: [6],
                        },
                        {
                            x: "asad",
                            y: [10],
                        },
                    ],
                },

            ],

        };


    },
}
</script>
