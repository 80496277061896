<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-5">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">All Collaborations</h5>
                        <!-- <router-link to="/invest-with-tma/add" class="btn btn-sm btn-primary mb-2 float-end">Add Collaborate</router-link> -->
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" :isViewAble="isViewAble" :items="items"
                            @deleted="fetch" @nextPage="nextPage" @clear="fetch" @search="fetch"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "User",
                    value: "user",
                    secondValue: "email",
                },
                {
                    text: "Service Type",
                    value: "service_type",
                    secondValue: "name",
                },
                {
                    text: "Co-fund Amount",
                    value: "co_fund_amount",
                },

            ],
            module: "investWithTma",
            isViewAble: true
        };
    },
    created() {
        this.fetch();
    },

    methods: {
        fetch(search = "") {
            this.loading = true;
            this.$store
                .dispatch("services/getAll", { search, paginate: true })
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        nextPage(url, search = "") {
            this.loading = true;
            const completeUrl = `${url}&paginate=true&search=${search}&type=Resolution`;
            axiosInstance.get(completeUrl).then((response) => {
                this.items = response.data.data;
                this.loading = false;
            });
        },
    },
};
</script>