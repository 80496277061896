<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">Sub Divisions</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/tehsil/add" class="btn btn-sm btn-primary">Add Sub Division</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
              :items="items" @deleted="fetch" :isEdit="isedit" :isDelete="isdelete" @nextPage="nextPage" @clear="fetch"
              @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "District",
          value: "district",
          secondValue: "name",
        },
      ],
      module: "tehsil",
      loading: false,
      isedit: true,
      isdelete: true,
      searchBar: true,
      pagination: true,
      permissions: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("tehsil/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>