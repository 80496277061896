<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/service-types" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Service type</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Name</label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="serviceType.name" />
              <label>Description</label>
              <QuillEditor v-model="serviceType.description" :editorHeight="'300px'" :editorWidth="'800px'" />
              <div class="mt-3 d-flex align-items-center">
                  <label class="me-3">Status</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="registervolunteer"  v-model="serviceType.status"
                      :true-value="1" :false-value="0" />
                  </div>
                </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      serviceType: {
      },
      serviceId: null,
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.serviceId = this.$route.params.id;
    this.fetch(this.serviceId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("serviceTypes/getSingle", { id })
        .then((response) => {
          this.serviceType = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
                        router.push({ path: "/service-types" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.serviceType);
      const id = this.serviceId;
      this.$store
        .dispatch("serviceTypes/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
    