<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">

        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">TMA's</h5>
            <router-link to="/tma/add" v-if="permissions && permissions.includes('tma')"
              class="btn btn-sm btn-primary mb-2 float-end">Add Tma</router-link>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :items="items" :isEdit="isEdit" :isDelete="isDelete"
              :isChangePassword="changePassword" @deleted="fetch" @nextPage="nextPage" @clear="fetch"
              @search="fetch"></data-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "District",
          value: "district",
          secondValue: "name",
        },
        {
          text: "Sub Division",
          value: "tehsil",
          secondValue: "name",
        },
        {
          text: "Email",
          value: "email",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: false,
      permissions: null,
      isEdit: false,
      isDelete: false,
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("tma")) ? true : false;
    this.isDelete = (this.permissions && this.permissions.includes("tma")) ? true : false;
    this.changePassword = (this.permissions && this.permissions.includes("tma")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
      .dispatch("admins/getAllTmas", { query: { search, paginate: "true", id } })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
