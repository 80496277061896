<template>
    <div class="col-xxl-4 col-xl-5 col-md-6 col-sm-7 notification box-col-6">
        <div class="card height-equal">
            <div class="card-header card-no-border">
                <div class="header-top">
                    <h5 class="m-0">Campaign</h5>
                    <div class="card-header-right-icon">

                    </div>
                </div>
            </div>
            <div class="card-body pt-0">
                <tr v-if="data?.campaign?.length === 0">
                    <td colspan="3" class="text-center">No Campaigns Added</td>
                </tr>
                <ul>
                    <li class="d-flex" v-for="item in data.campaign" :key="item">
                        <div :class="item.class"></div>
                        <div class="w-100 ms-3">
                            <p class="d-flex justify-content-between mb-2">
                                <span class="date-content light-background">{{ item.format_created_at }}</span><span>{{
                                    item.human_readable_created_at }}</span>
                            </p>
                            <h6>
                                {{ item.title }}<span class="dot-notification"></span>
                            </h6>
                            <!-- <p  class="f-light">
                            {{ item.detail }}
                        </p> -->
                            <p class="f-light" v-html="item.detail"></p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import DropDown2 from "../base/dropdown/DropDown2.vue";
import {
    activities
} from "../../../data/dashboard/default"
import {
    apexDashboard
} from "../../../data/comon";
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        chart: [],
    },
    components: {
        DropDown2
    },
    data() {
        return {
            apexDashboard: apexDashboard,
            activities: activities
        };
    },
}
</script>
