<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Feedbacks</h5>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :isEdit="isEdit" :isDelete="isDelete" :items="items"
              @deleted="fetch" @nextPage="nextPage" @clear="fetch" @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "User Name",
          value: "user",
          secondValue: "name",
        },
        {
          text: "Module",
          value: "module",
          secondValue: "key",
        },
        {
          text: "Feedback",
          value: "feedback",
        },
        {
          text: "Rating",
          value: "rating",
        },

      ],

    };
  },
  created() {
    this.fetch();
    // this.permissions = localStorage.getItem("permissions");
    // this.isEdit = (this.permissions && this.permissions.includes("newsAndEvent-Edit")) ? true : false;
    // this.isDelete = (this.permissions && this.permissions.includes("newsAndEvent-Delete")) ? true : false;

  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("feedback/getUsersFeedback", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
          this.items.data.forEach(item => {
            if (item.module == null) {
              item.module = { key: 'APP' };
            }
          });

        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
  nextPage(url, search = "") {
    this.loading = true;
    const completeUrl = `${url}&paginate=true&search=${search}`;
    axiosInstance.get(completeUrl).then((response) => {
      this.items = response.data.data;
      this.items.data.forEach(item => {
        if (item.module == null) {
          item.module = { key: 'APP' };
        }
      });
      this.loading = false;
    });
  },
};
</script>