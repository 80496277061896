<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/complaints" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Complaint</h4>
                    </div>
                    <div class="card-body">
                        <form>
                            <label>User Name</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="complaint.user_profile.name" />
                            <label>Email</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="complaint.user.email" />
                            <label>Complain Type</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="complaint.complaint_type.name" />
                           <div v-if="complaint.status=='Resolved'">
                            <label>Status</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="complaint.status" />
                           </div>
                            <label>Complain</label>
                            <textarea class="form-control form-control-sm mb-3" rows="8"
                                v-model="complaint.complaint_text"></textarea>

                            <button v-if="complaint.status=='InProgress'" type="button" @click="resolvedComplaint" class="btn btn-sm btn-primary">
                                Resolve Complaint
                            </button>
                           




                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
    data() {
        return {
            complaint: {
                user: {
                    email: ''
                },
                complaint_type: {
                    name: ''
                },
                user_profile: {
                    name: ''
                }
            },
            complaintId: null,
            loading: false,
            modules: [],
        };
    },
    mounted() {
        this.complaintId = this.$route.params.id;
        this.fetch(this.complaintId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("complaints/getSingle", { id })
                .then((response) => {
                    this.complaint = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        router.push({ path: "/complaints" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
                });
        },

        resolvedComplaint() {
            this.loading = true;
            const data = "";
            const id=this.complaintId;
            this.$store
                .dispatch("complaints/update", { data, id })
                .then((response) => {
                    this.$toast.show("Successfully Update", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 2000,
                    });
                     this.fetch(this.complaintId);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        }
    },
};
</script>
