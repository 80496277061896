import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import auth from "./modules/auth";
import roles from "./modules/roles";
import admins from "./modules/admins";
import module from "./modules/module";
import campaigntypes from "./modules/campaigntypes";
import campaigns from "./modules/campaigns";
import projects from "./modules/projects";
import managmentTeam from "./modules/managmentTeam";
import aboutTmaVideos from "./modules/aboutTmaVideos";
import complainttypes from "./modules/complaintTypes";
import complaints from "./modules/complaints";
import news from "./modules/news";
import event from "./modules/events";
import serviceTypes from "./modules/serviceTypes";
import services from "./modules/getServices";
import investWithTma from "./modules/investwithtma";
import successStories from "./modules/successStories";
import activeCitizen from "./modules/activeCitizen";
import resolution from "./modules/resolution";
import users from "./modules/users";
import aboutus from "./modules/aboutus";
import disscusionBoard from "./modules/disscusionBoard";
import feedback from "./modules/feedback";
import notification from "./modules/notification";
import districts from "./modules/districts";
import tehsil from "./modules/tehsil";
import area from "./modules/areas";
import web from "./modules/web";
import dashboard from "./modules/dashboard";
import reporting from "./modules/reporting";
import gallary from "./modules/gallary";
import press from "./modules/press";
import poll from "./modules/poll";
import survey from "./modules/survey";
import tma_events from "./modules/tma_events";
import professions from "./modules/profession";

export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    layout,
    menu,
    auth,
    roles,
    admins,
    module,
    campaigntypes,
    campaigns,
    projects,
    managmentTeam,
    aboutTmaVideos,
    complainttypes,
    complaints,
    news,
    event,
    serviceTypes,
    services,
    investWithTma,
    successStories,
    activeCitizen,
    resolution,
    users,
    aboutus,
    disscusionBoard,
    feedback,
    notification,
    districts,
    tehsil,
    area,
    web,
    dashboard,
    reporting,
    gallary,
    press,
    poll,
    survey,
    tma_events,
    professions
  },
});
