<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/request-services" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Request Service</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addRole">
                            <label>User<span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm mb-3" required v-model="serviceRequest.user_id">
                                <option disabled value="">Select User</option>
                                <option v-for="user in users" :key="user.id" :value="user.id">
                                    {{ user.email }}
                                </option>
                            </select>
                            <label>Service <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm mb-3" required v-model="serviceRequest.service_id">
                                <option disabled selected>Select Service Type</option>
                                <option v-for="type in types" :key="type.id" :value="type.id">
                                    {{ type.name }}
                                </option>
                            </select>
                            <label>Amount <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="serviceRequest.co_fund_amount" />
                            <label>Detail <span class="text-danger">*</span></label>
                            <textarea class="form-control form-control-sm mb-3" rows="4"
                                v-model="serviceRequest.co_fund_details" required></textarea>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            serviceRequest: {

            },
            loading: false,
            modules: [],
            types: [],
            users: [],
        };
    },
    mounted() {
        this.fetchModules();
        this.fetchServices();
        this.fetchUsers();
    },
    methods: {
        submit() {
            this.loading = true;
            const data = toRaw(this.serviceRequest);

            this.$store
                .dispatch("services/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchModules() {
            this.loading = true;
            this.$store
                .dispatch("module/getAll")
                .then((response) => {
                    this.loading = false;
                    this.modules = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        fetchServices() {
            this.loading = true;
            this.$store
                .dispatch("serviceTypes/getAll")
                .then((response) => {
                    this.loading = false;
                    this.types = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.users = response.data;
                    console.log(this.users);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>
      