<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/app-users" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">User Profile</h4>
                    </div>
                    <div class="card-body">
                        <div class="form-group mt-2 row">
                            <div class="col-md-6">
                                <label class="text-bold">Full Name</label>
                                <p class="form-control-static">{{ admin.name || 'N/A' }}</p>
                            </div>
                            <div class="col-md-6">
                                <label class="text-bold">Profession</label>
                                <p class="form-control-static">{{ admin?.profession?.name || 'N/A' }}</p>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="text-bold">CNIC</label>
                            <p class="form-control-static">{{ admin.cnic || 'N/A' }}</p>
                        </div>
                        <div class="form-group mt-2">
                            <label class="text-bold">Phone</label>
                            <p class="form-control-static">{{ admin.phone_number || 'N/A' }}</p>
                        </div>
                        <!-- <div class="form-group mt-2">
                            <label>Area</label>
                            <p class="form-control-static">{{ getAreaName(admin.area_id) }}</p>
                        </div> -->
                        <div class="form-group mt-2 row">
                            <div class="col-md-12">
                                <label class="text-bold">Date of Birth</label>
                                <p class="form-control-static">{{ admin.date_of_birth }}</p>
                            </div>
                            <div class="col-md-12">
                                <label class="text-bold">Gender</label>
                                <p class="form-control-static">{{ admin.gender || 'N/A' }}</p>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="text-bold">Email</label>
                            <p class="form-control-static">{{ admin.email || 'N/A' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import isNumber from "@/helpers/helpers";
import router from "@/router";

export default {
    mixins: [isNumber],
    components: {
        QuillEditor,
    },
    data() {
        return {
            admin: [],
            newsId: null,
            loading: false,
            modules: [],
            users: [],
            userID: "",
            areas:[],
            errors:[],
        };
    },
    mounted() {
        this.userID = this.$route.params.id;
        this.fetch(this.userID);
        this.fetchUsers();
        this.fetchDistricts();
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("users/getSingle", { id })
                .then((response) => {
                    if (response === null || response.data === null) {
                        router.push({ path: "/app-users" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    } else {
                        this.admin = response.data;
                        this.admin = { ...this.admin, ...this.admin.profile };
                        delete this.admin.profile;
                        this.loading = false;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.admin.profile);
            const id = this.userID;
            this.$store
                .dispatch("users/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.users = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/showDistrictData")
                .then((response) => {
                   this.admin.district_id=response.data.district_id;
                   this.admin.tehsil_id=response.data.tehsil_id;
                   this.areas=response.data.areas;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>


<style scoped>
.form-control-static {
    font-size: 0.95rem;
    padding: 0.25rem 0;
    border-bottom: 1px solid #ddd;
    color: #333;
}
.text-bold {
    font-weight: bold;
}
</style>