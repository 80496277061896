<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/tma_events" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Event</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="event.name"
              />
              <!-- New input for description -->
              <div class="d-flex flex-row align-items-center mb-2">
                <div>
                  <label class="me-3 mb-0">Select TMA's</label>
                </div>
                <div class="d-flex align-items-center">
                  <input
                    type="checkbox"
                    :checked="event.tmas?.length == tmas.length"
                    style="margin: 0"
                    @change="toggleSelectAll"
                    id="selectAll"
                    class="form-check-input me-2"
                  />
                  <label for="selectAll" class="mb-0">
                    {{
                      event.tmas && event.tmas.length === tmas.length
                        ? "Unselect All"
                        : "Select All"
                    }}
                  </label>
                </div>
              </div>

              <multiselect
                class="mb-3"
                v-model="event.tmas"
                :options="tmas"
                :multiple="true"
                :close-on-select="false"
                placeholder="Select TMA's"
                :custom-label="tmaFormatedName"
                track-by="id"
              >
              </multiselect>
              <label>Date & Time<span class="text-danger">*</span></label>
              <input
                type="datetime-local"
                class="form-control form-control-sm mb-3"
                v-model="event.date"
                required
              />
              <label>Event Type<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                v-model="event.event_type"
                required
              />
              <label>Location<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                v-model="event.location"
                required
              />
              <label>Upload Image<span class="text-danger">*</span></label
              ><br />
              <input
                ref="video"
                type="file"
                accept="image/*"
                class="form-control"
                @change="handleFileUpload"
                required
              />
              <img
                v-if="mediaType === 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100"
              />
              <video
                class="mt-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'video'"
              />
              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <label class="mt-2">PDF Document</label>
                <input
                  ref="fileInput"
                  @change="uploadPDF"
                  type="file"
                  accept="application/pdf"
                  class="form-control"
                />
              </div>
              <div v-if="pdfUrl" class="mt-2">
                <a
                  class="mt-2 link-with-underline"
                  v-if="pdfUrl"
                  :href="pdfUrl"
                  target="_blank"
                  >View PDF</a
                >
              </div>
              <label class="mt-3">Detail</label>
              <QuillEditor
                v-model="event.description"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" :disabled="uploadProgress > 0" class="btn btn-sm btn-primary">
                    {{ uploadProgress > 0  ? 'Please wait, file uploading...' : 'Save' }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
    Multiselect,
  },
  mixins: [helpers],
  data() {
    return {
      files: [],
      event: {
        image: null,
        date: null,
        description: "",
        location: "",
        document: "",
        event_type: "",
        name: "",
        tmas: [],
      },
      loading: false,
      modules: [],
      imagePreview: null,
      pdfPreview: null,
      mediaType: "",
      pdfUrl: "",
      tmas: [],
      selectedOptions: [],
      uploadProgress:-1,
    };
  },

  created() {
    this.fetchTmas();
  },

  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "TMAEvents",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.event.image = filePath;
        this.uploadProgress = -1;
      }
    },
    uploadPDF(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = btoa(
          new Uint8Array(reader.result).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        this.event.document = `data:application/pdf;base64,${base64String}`;
        const blob = new Blob([reader.result], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      };
      reader.readAsArrayBuffer(file);
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.event);
      this.$store
        .dispatch("tma_events/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.event).forEach((key) => (this.event[key] = null));
            this.$refs.video.value = "";
            this.$refs.fileInput.value = "";
            this.event.status = 0;
            this.event.notification = 0;
            this.mediaType = "";
            this.pdfUrl = "";
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    handleNotificationChange() {
      if (this.event.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.event.notificationFilter = result;
    },
    fetchTmas() {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAllTmas", { query: { id } })
        .then((response) => {
          this.tmas = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    tmaFormatedName(tma) {
      return tma.name + " District:" + tma.district.name;
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.event.tmas = this.tmas;
      } else {
        this.event.tmas = [];
      }
    },
  },
};
</script>