<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-5">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">Active Citizens</h5>
                        <router-link v-if="permissions && permissions.includes('activeCitizen-Add')"
                            to="/active-citizen/add" class="btn btn-sm btn-primary mb-2 float-end">Add Active
                            Citizen</router-link>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :isEdit="isEdit" :isDelete="isDelete" :module="module"
                            :items="items" @deleted="fetch" @nextPage="nextPage" @clear="fetch"
                            @search="fetch"></data-table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "Email",
                    value: "user",
                    secondValue: "email",
                },
                {
                    text: "Name",
                    value: "user_profile",
                    secondValue: "name",
                },
                {
                    text: "Phone",
                    value: "user",
                    secondValue: "phone_number",
                },
                {
                    text: "Created at",
                    value: "created_at",
                },

            ],
            module: "activeCitizen",
            permissions: null,
            isEdit: false,
            isDelete: false,
            loading:false
        };
    },
    created() {
        this.fetch();
        this.permissions = localStorage.getItem("permissions");
        this.isEdit = (this.permissions && this.permissions.includes("activeCitizen-Edit")) ? true : false;
        this.isDelete = (this.permissions && this.permissions.includes("activeCitizen-Delete")) ? true : false;
    },
    methods: {
        fetch(search = "") {
            this.loading = true;
            this.$store
                .dispatch("activeCitizen/getAll", { search, paginate: true })
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        nextPage(url, search = "") {
            this.loading = true;
            const completeUrl = `${url}&paginate=true&search=${search}`;
            axiosInstance.get(completeUrl).then((response) => {
                this.items = response.data.data;
                this.loading = false;
            });
        },
    },
};
</script>