import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get("/gallary")
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    folderMedia({ dispatch, commit },{id}) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/folder-media/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/events/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/gallary", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    storeGallaryMedia({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/store-gallary-media", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/gallary/${id}`, data)
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/gallary/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    deleteMedia({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/delete-gallary-media", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
