<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/web-content/add" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="testimonial.name" />

                            <label>Description <span class="text-danger">*</span></label>
                            <textarea rows="7" class="form-control form-control-sm mb-3" required
                                v-model="testimonial.description" />
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            news: {
                image: null,
            },
            id: null,
            loading: false,
            testimonial: []
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetch(this.id);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("web/getSingle", { id })
                .then((response) => {
                    this.loading = false;
                    this.testimonial = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.testimonial);
            const id = this.id;
            this.$store
                .dispatch("web/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>