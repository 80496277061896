<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h5 class="card-title mb-0">Partnership with TMA</h5>
          </div>
          <div class="card-body">
            <data-table
              :columns="columns"
              :module="module"
              :isViewAble="isViewAble"
              :items="items"
              @deleted="fetch"
              @nextPage="nextPage"
               @clear="fetch"
                @search="fetch"
            ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "User",
          value: "user",
          secondValue: "email",
        },
        {
          text: "Service Type",
          value: "service_type",
          secondValue: "name",
        },
        {
          text: "Co-fund Amount",
          value: "co_fund_amount",
        },
      ],
      module: "partnerwithtma",
      isViewAble: true,
      loading: false,
    };
  },
  created() {
    this.fetch();
  },

  methods: {
    fetch(search = "") {
      console.log("hello");
      this.loading = true;
      this.$store
        .dispatch("investWithTma/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
  