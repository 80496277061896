<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-title">All Professions</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/professions/add" class="btn btn-sm btn-primary"
                  v-if="permissions && permissions.includes('setting')">Add Profession</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
              :isEdit="isedit" :isDelete="isdelete" :items="items" @deleted="fetch" @nextPage="nextPage" @clear="fetch"
              @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      module: "professions",
      loading: false,
      isedit: false,
      isdelete: false,
      searchBar: true,
      pagination: true,
      permissions: [],
    };
  },
  created() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isedit = (this.permissions && this.permissions.includes("setting")) ? true : false;
    this.isdelete = (this.permissions && this.permissions.includes("setting")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("professions/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>