<!-- <template>
  <router-link to="/dashboard">
    <img class="img-fluid for-light" src="../../assets/images/logo/logo_white.png" alt="" />
    
  </router-link>
</template> -->
<script>
export default {
  name: "Logo",
};
</script>
