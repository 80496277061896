<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/success-stories" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Story</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>User<span class="text-danger">*</span></label>
              <select
                class="custom-select mb-3"
                v-model="story.user_id"
                required
              >
                <option value="" selected>Select User</option>
                <option v-for="t in users" :value="t.id" :key="t.id">
                  {{ t.email }}
                </option>
              </select>

              <div v-if="error" class="mb-3">
                <span class="text-danger">{{ error }}</span>
              </div>

              <div class="mb-3 d-flex align-items-center">
                <label class="me-3">Send Notification</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sendNotificationYes"
                    v-model="story.notification"
                    :true-value="1"
                    :false-value="0"
                    @change="handleNotificationChange"
                  />
                </div>
              </div>

              <label>Content</label>
              <QuillEditor
                v-model="story.content"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
                required
              />

              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  data() {
    return {
      files: [],
      story: {
        image: null,
        name: null,
        content: null,
        user_id: "",
        notificationFilter: [],
        notification: 0,
      },
      loading: false,
      modules: [],
      imagePreview: null,
      users: [],
      error: "",
    };
  },

  mounted() {
    this.fetchUsers();
  },

  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.story.image = e.target.result;
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.story);
      this.error = "";
      this.$store
        .dispatch("successStories/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.story).forEach((key) => (this.story[key] = null));
            this.story.user_id = "";
            this.story.notification = 0;
            this.story.notificationFilter = [];
          } else if (response.success == false) {
            this.error = response.error;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    fetchUsers() {
      this.loading = true;
      this.$store
        .dispatch("users/getAll")
        .then((response) => {
          this.loading = false;
          this.users = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    handleNotificationChange() {
      if (this.story.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.story.notificationFilter = result;
    },
  },
};
</script>
      