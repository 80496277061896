<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <!-- <router-link to="/news" class="btn btn-sm btn-primary mb-3">Back</router-link> -->
        <div class="card">
          <div class="card-header" style="border-bottom: none">
            <h4 class="card-title">Settings</h4>
          </div>
          <div class="card-body">
            <div class="mb-4">
              <h5 class="mb-3" style="border-bottom: 1px solid #dee2e6">
                Feedback Modules
              </h5>
            </div>
            <div class="row mt-3" v-for="item in items" :key="item.module">
              <div class="col-md-8">
                <div class="col">
                  <label
                    :for="'toggleSwitch-' + item.module"
                    class="form-label"
                    style="font-weight: bold"
                    >{{ item.module }}</label
                  >
                </div>
                <div class="col-md-9">
                  This setting allows users to provide feedback for the
                  {{ item.module }} module.
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <input
                    class="form-check-input styled-checkbox"
                    type="checkbox"
                    :id="'toggleSwitch-' + item.module"
                    v-model="item.value"
                    true-value="1"
                    false-value="0"
                    @change="updateValue(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      loading: false,
      items: [],
      pages: [
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
        { name: "", image_url: "", perview: "" },
      ],
    };
  },

  created() {
    this.fetch();
    this.fetchAllPages();
  },

  methods: {
    handleImageUpload(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pages[index].perview = e.target.result;
          this.pages[index].image_url = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("feedback/getAll")
        .then((response) => {
          const excludeModules = [
            "Projects",
            "Collaborate  with TMA",
            "Complaints",
          ];
          this.items = response.data
            .filter((item) => !excludeModules.includes(item.module))
            .map((item) => {
              if (item.module === "Resolution") {
                item.module = "Press Release";
              }
              return item;
            });

          console.log("items", this.items);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchAllPages() {
      this.loading = true;
      this.$store
        .dispatch("feedback/getAllPages")
        .then((response) => {
          console.log("sss", response.data);

          response.data.forEach((item, index) => {
            this.pages[index].name = item.name;
            this.pages[index].perview =
              item.media && item.media.image_url ? item.media.image_url : "";
          });

          console.log("dsds", this.pages);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    updateValue(data) {
      this.$store
        .dispatch("feedback/update", { data })
        .then((response) => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    updateName(event, index) {
      this.pages[index].name = event.target.value;
    },
    submit() {
      this.loading = true;
      const data = this.pages;
      this.$store
        .dispatch("feedback/storePage", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            console.log("er", response.data);
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>


<style scoped>
.styled-checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid black; /* Black border */
  appearance: none; /* Remove default checkbox styling */
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px; /* Optional: Slightly rounded corners */
  background-color: white; /* Background color when unchecked */
}

.styled-checkbox:checked {
  background-color: black; /* Background color when checked */
  border-color: black; /* Keep the border black */
}

.styled-checkbox:focus {
  outline: 2px solid #333; /* Add an outline when focused */
}
</style>
