<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-5">
                <div class="col-md-12 mt-5">
                <router-link to="/request-service/add" class="btn btn-sm btn-primary mb-2 float-end">Add Service Request</router-link>
            </div>
            </div>
            <div class="col-md-12">
                <data-table :columns="columns" :module="module" :items="items" @deleted="fetch"></data-table>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "Amount",
                    value: "co_fund_details",
                },
            ],
            module: "services",
        };
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("services/getAll")
                .then((response) => {
                    console.log("hello world");
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>
  