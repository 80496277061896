<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/survey" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header d-flex flex-row justify-content-between">
                        <h4 class="card-title">Edit {{ disscusionBoard?.title }}</h4>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#totalParticipantModal"
                            @click.prevent="viewSurvey">View Survey Questions</a>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addTeam">
                            <label>Title <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="disscusionBoard.title" />
                            <label>Start Date <span class="text-danger">*</span></label>
                            <input type="date" v-model="disscusionBoard.start_date"
                                class="form-control form-control-sm mb-3" required />
                            <label>End Date <span class="text-danger">*</span></label>
                            <input type="date" v-model="disscusionBoard.end_date"
                                class="form-control form-control-sm mb-3" required />

                            <!-- <div class="mt-3" v-if="disscusionBoard.type=='poll' || disscusionBoard.type=='survey'">
                                <p>
                                    <a href="javascript:void(0);"
                                        class="primary-text-color text-underline" data-bs-toggle="modal"
                                        data-bs-target="#totalParticipantModal">View {{ disscusionBoard.type }}</a>
                                </p>
                            </div> -->


                            <div class="mb-2" v-if="disscusionBoard.type === 'chat'">
                                <label>Chat Topic</label>
                                <QuillEditor v-model="disscusionBoard.message" :editorHeight="'300px'"
                                    :editorWidth="'800px'" required />
                            </div>







                            <div class="mb-2" v-if="disscusionBoard.type === 'poll'">
                                <label>Title</label>
                                <textarea class="form-control form-control-sm mb-3" rows="2"
                                    v-model="disscusionBoard.poll.question" required></textarea>

                                <div class="row">
                                    <template v-for="(obj, index) in disscusionBoard.poll.options" :key="index">
                                        <div class="d-flex justify-content-end mt-3">
                                            <!-- <button type="button" class="btn btn-sm btn-danger"
                                                @click="removeObjective(index)">
                                                Remove
                                            </button> -->
                                        </div>
                                        <label>Option {{ index + 1 }}</label>
                                        <div>
                                            <input class="form-control form-control-sm"
                                                v-model="disscusionBoard.poll.options[index].option" required>
                                        </div>

                                    </template>

                                </div>

                                <!-- <div class="row mt-3">
                                    <div class="col-md-12">
                                        <button @click="addPollOptions" class="btn btn-sm btn-primary">
                                            Add Option
                                        </button>
                                    </div>
                                </div> -->
                            </div>



                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div class="modal fade" id="totalParticipantModal" tabindex="-1"
                        aria-labelledby="totalParticipantModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="container mt-4" v-if="disscusionBoard.type === 'survey'">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title text-center mb-4">Survey</h5>
                                                <template v-for="(question, questionIndex) in disscusionBoard.survey"
                                                    :key="questionIndex">
                                                    <div class="mb-4">
                                                        <h6>Question {{ questionIndex + 1 }}</h6>
                                                        <p class="mb-2"><strong>{{ question.question }}</strong></p>

                                                        <ul class="list-group">
                                                            <li v-for="(option, optionIndex) in question.options"
                                                                :key="optionIndex" class="list-group-item">
                                                                <span class="badge badge-primary mr-2">Option {{
                                                                    optionIndex + 1
                                                                    }}</span>
                                                                {{ option.option }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <hr>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>

    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            files: [],
            disscusionBoard: {
                title: null,
                message: null,
                type: null,
                start_date: null,
                end_date: null,
                image: null,
                poll: {
                    question: [],
                    options: [{ option: "" }]
                },
                survey: [{
                    question: "",
                    options: [{ option: "" }]
                }],
            },
            pollOptions: {
                option: ""
            },
            surveyDissucssion: {
                question: "",
                options: [{
                    option: ""
                }]
            },
            loading: false,
            modules: [],
            imagePreview: null, 
            surveyTitle:"",
        };
    },
    mounted() {
        this.disscusionBoardId = this.$route.params.id;
        this.fetch(this.disscusionBoardId);
    },
    methods: {
        addSurveyQuestion(e) {
            e.preventDefault();
            this.disscusionBoard.survey.push({ question: this.surveyDissucssion.question, options: [{ option: "" }] });
            this.surveyDissucssion.question = "";
        },
        addSurveyOption(question, event) {
            event.preventDefault();
            question.options.push({ option: "" });
        },
        addPollOptions(e) {
            e.preventDefault();
            this.disscusionBoard.poll.options.push({ option: this.pollOptions.option });
            this.pollOptions.option = "";
        },
        removeObjective(index) {
            this.disscusionBoard.poll.options.splice(index, 1);
        },
        removeOptionSurvey(question, index) {
            question.options.splice(index, 1);
        },
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("disscusionBoard/getSingle", { id })
                .then((response) => {
                    this.disscusionBoard.title = response.data.title;
                    this.surveyTitle=response.data.title;
                    this.disscusionBoard.start_date = response.data.start_date;
                    this.disscusionBoard.end_date = response.data.end_date;
                    this.disscusionBoard.type = response.data.type;
                    if (response.data.type == 'poll' && response.data.question) {
                        this.disscusionBoard.poll.question.push(response.data.question.question);
                        this.disscusionBoard.poll.options = response.data.question.survey_options.map(item => {
                            return { option: item.option };
                        });
                    }
                    if (response.data.type == 'survey' && response.data.survey_questions) {
                        this.disscusionBoard.survey = response.data.survey_questions.map(item => {
                            return {
                                question: item.question,
                                options: item.survey_options.map(optionItem => {
                                    return { option: optionItem.option };
                                })
                            };
                        });
                    }
                    if (response.data.type == 'chat') {
                        this.disscusionBoard.message = response.data.message;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        router.push({ path: "/survey" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.disscusionBoard);
            const id = this.disscusionBoardId;
            this.$store
                .dispatch("disscusionBoard/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>