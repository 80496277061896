export const   overallbalance =[{
    iconName: "user",
    title: "Active",
    amount: "3",
  
    balanceClass: "font-success"
},
{
    iconName: "expense",
    title: "InActive",
    amount: "1",
 
    balanceClass: "font-danger"
},
{
    iconName: "doller-return",
    title: "Active Citizen",
    amount: "1",
}
]

export const activities = [{
    date: "17 jan, 2024",
    day: "1 day ago",
    title: "Empowering Governance",
    decription: "Exciting to see a campaign focused on 'Empowering Governance",
    class: "activity-dot-primary"

}, {
    date: "18 Jan, 2024",
    day: "Today",
    title: "Empowering Governance",
    decription: "Kudos to the 'Empowering Governance' campaign! The name itself resonates with a commitment to strengthen our governance systems.",
    class: "activity-dot-warning"

},
{
    date: "18 Jan, 2024 ",
    day: "12:00 PM",
    title: " Empowering Governance",
    decription: "Absolutely love the concept behind 'Empowering Governance.' It's crucial to have leaders who prioritize transparency, accountability, and community involvement.",
    class: "activity-dot-secondary"

}
]

export const    resentSales = [{
    image:  "/1.png",
    name: "CitizenConnect Initiative",
    time: "2024 to 2025",
   
},
{
  image: "/99.jpg",
    name: "SmartGov Solutions",
    time: "2024 to 2025",
   
},
{
  image: "/66.jpg",
    name: "UnityInAction Project",
    time: "2024 to 2025",
  
},
{
  image: "/4.jpg",
    name: "Inclusive Governance Program",
    time: "2024 to 2025",
    
},
{
    image: "/5.jpg",
    name: "FutureForward Policy Initiative",
    time: "2024 to 2025",
  
}, 
]

export const paperNotes = [{
    label: "SAAS",
    labelClass: "badge badge-light-primary",
}, {
    label: "E-Commerce",
    labelClass: "badge badge-light-success",
}, {
    label: "Crypto",
    labelClass: "badge badge-light-warning",
}, {
    label: "Project",
    labelClass: "badge badge-light-info",
}, {
    label: "NFT",
    labelClass: "badge badge-light-secondary",
}, {
    label: "+9",
    labelClass: "badge badge-light-light",
}, ]

export const  customers = [{
    image: require("@/assets/images/dashboard/user/1.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/6.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/7.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/3.jpg"),
}, {
    image: require("@/assets/images/dashboard/user/8.jpg"),
}, ]

export const puechaseSalesDetails1 = [{
    widgetClass: "widget-round secondary",
    svgIcon1: "cart",
    svgIcon2: "halfcircle",
    number: "10,000",
    title: "Purchase",
    growthClass: "font-secondary f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+50%"

},
{
    widgetClass: "widget-round primary",
    svgIcon1: "tag",
    svgIcon2: "halfcircle",
    number: "4,200",
    title: "Sales",
    growthClass: "font-primary f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+70%",

}
]
export const puechaseSalesDetails2 = [{
    widgetClass: "widget-round warning",
    svgIcon1: "return-box",
    svgIcon2: "halfcircle",
    number: "7000",
    title: "Sales return",
    growthClass: "font-warning f-w-500",
    iconClass: "icon-arrow-down icon-rotate me-1",
    growthNumber: "-20%"

},
{
    widgetClass: "widget-round success",
    svgIcon1: "rate",
    svgIcon2: "halfcircle",
    number: "5700",
    title: "Purchase rate",
    growthClass: "font-success f-w-500",
    iconClass: "icon-arrow-up icon-rotate me-1",
    growthNumber: "+70%",

}
]