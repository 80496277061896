<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">Search And Report</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitReport">
                            <div class="form-group" v-if="role == 'super-admin'">
                                <div class="col-md-6">
                                    <label>Select TMA<span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" v-model="filter.tmaId" required>
                                        <option value="">Select...</option>
                                        <option v-for="tma in tmas" :value="tma.id"> {{ tma.name }}-{{ tma.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <label>Select Module <span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" @change="getData" v-model="filter.type" required>
                                        <option value="">Select...</option>
                                        <option value="users">Users</option>
                                        <option value="disscussion">Disscussion Board</option>
                                        <option value="survey">Survey</option>
                                        <option value="poll">Poll</option>
                                        <option value="campaign">User Campaign Registration</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="filter.type == 'campaign'">
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Select Campaign<span class="text-danger">*</span></label>
                                        <select class="custom-select mb-3" v-model="filter.campaignId" required>
                                            <option value="">Select...</option>
                                            <option v-for="campaign in campaigns" :value="campaign.id">{{ campaign.title
                                                }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Start Date </label>
                                        <input type="date" v-model="filter.startDate" class="custom-select mb-3">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>End Date </label>
                                        <input type="date" v-model="filter.endDate" class="custom-select mb-3">
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="filter.type == 'disscussion' || filter.type == 'survey' || filter.type == 'poll'">
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label v-if="filter.type=='survey' || filter.type=='poll'">Select {{ filter.type }}<span class="text-danger">*</span></label>
                                        <label v-else>Select disscussion board<span class="text-danger">*</span></label>
                                        <select class="custom-select mb-3" v-model="filter.disscusionId" required>
                                            <option value="">Select...</option>
                                            <option v-for="board in boards" :value="board.id">{{ board.title }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Start Date </label>
                                        <input type="date" v-model="filter.startDate" class="custom-select mb-3">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>End Date </label>
                                        <input type="date" v-model="filter.endDate" class="custom-select mb-3">
                                    </div>
                                </div>
                            </div>
                            <div v-if="filter.type == 'users'">
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Start Date </label>
                                        <input type="date" v-model="filter.startDate" class="custom-select mb-3">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>End Date </label>
                                        <input type="date" v-model="filter.endDate" class="custom-select mb-3">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary mt-3" style="color:white;">Download</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from 'vue3-datepicker';
import 'vue3-datepicker/dist/vue3-datepicker.css';
import axios from 'axios';
import areas from '@/store/modules/areas';
import roles from '@/store/modules/roles';
import reporting from '@/store/modules/reporting';

export default {
    components: {
        VueDatePicker
    },
    data() {
        return {
            filter: {
                type: "",
                startDate: null,
                endDate: null,
                formId: "",
                district_id: "",
                tehsil_id: "",
                area_id: "",
                tmaId: "",
                disscusionId: "",
                campaignId: "",
            },
            forms: [],
            districts: [],
            tehsils: [],
            areas: [],
            tmas: [],
            boards: [],
            campaigns: [],
            role: localStorage.getItem('role'),
            loading: false
        };
    },
    created() {
        this.fetchTmas();

    },
    methods: {
        async submit() {
            try {
                this.loading = true;
                const baseURL = `${process.env.VUE_APP_BASE_URL}/reporting`;
                const response = await axios.post(baseURL, this.filter, { responseType: 'blob' });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log("Error downloading file:", error);
                this.$toast.show(error.message, {
                    theme: "outline",
                    position: "top",
                    type: "error",
                    duration: 10000,
                });
            }
        },

        submitReport() {
            this.loading = true;
            const data = this.filter;
            this.$store
                .dispatch("reporting/store", { data, type: { responseType: 'blob' } })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'data.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.loading = false;
                })
                .catch((e) => {
                    console.error("Report download failed:", e);
                    this.loading = false;
                });
        },
        fetchTmas() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAllTmas", { query: { id } })
                .then((response) => {
                    this.tmas = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        getData() {
            if (this.filter.type == 'disscussion' || this.filter.type == 'poll' || this.filter.type == 'survey') {
                const id = this.filter.tmaId;
                this.$store
                    .dispatch("disscusionBoard/getTmaBoards", { id })
                    .then((response) => {
                        this.boards = response.data;
                        if (this.filter.type == 'poll') {
                            this.boards = this.boards.filter(board => board.type == 'poll');
                        } else if (this.filter.type == 'survey') {
                            this.boards = this.boards.filter(board => board.type == 'survey');
                        } else {
                            this.boards = this.boards.filter(board => board.type == 'chat');
                        }
                        this.filter.disscusionId="";
                        this.filter.campaignId=""
                    })
                    .catch((e) => {
                        console.log(e.error);

                    });
            } else if (this.filter.type == 'campaign') {
                const id = this.filter.tmaId;
                this.$store
                    .dispatch("campaigns/getTmaCampaigns", { id })
                    .then((response) => {
                        this.campaigns = response.data;

                    })
                    .catch((e) => {
                        console.log(e.error);

                    });

            }

        }
    },

};
</script>
