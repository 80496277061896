<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/projects" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Project</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addProject">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.project_name"
              />

              <label>Location<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.location"
              />
              <label>Start Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.start_date"
              />
              <label>End Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.end_date"
              />
              <label>Status <span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusOngoing"
                    name="status"
                    value="Ongoing"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusOngoing"
                    >Ongoing</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusCompleted"
                    name="status"
                    value="Completed"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusCompleted"
                    >Completed</label
                  >
                </div>
              </div>
              <label>Reference to<span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ddcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="DDC"
                    required
                  />
                  <label class="form-check-label" for="ddcRadio">DDC</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="tdcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="TDC"
                    required
                  />
                  <label class="form-check-label" for="tdcRadio">TDC</label>
                </div>
              </div>
              <div class="mb-3">
                <label
                  >Upload Cover Image/Video<span class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  type="file"
                  ref="video"
                  accept="image/*,video/*"
                  class="form-control"
                  @change="handleFileUpload"
                />
              </div>
              <img
                v-if="mediaType == 'Image' || mediaType == 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100 mb-2"
              />
              <video
                class="mt-2 mb-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'Video' || mediaType == 'video'"
              />

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <label>Description</label>
              <QuillEditor
                v-model="project.description"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      uploadProgress > 0
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";
import helpers from "@/helpers/helpers";
export default {
  components: {
    QuillEditor,
  },
  mixins: [helpers],
  data() {
    return {
      project: {
        image: null,
        images: [],
        status: "",
      },
      roleId: null,
      loading: false,
      modules: [],
      mediaType: "",
      imagePreview: null,
      uploadProgress: -1,
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    this.fetch(this.roleId);
  },
  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Projects",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.project.image = filePath;
        this.uploadProgress = -1;
      }
    },
    handleMultipleImageUpload(event) {
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.project.images.push(e.target.result);
          };
          reader.readAsDataURL(files[i]);
        }
      }
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("projects/getSingle", { id })
        .then((response) => {
          this.project = response.data;
          this.mediaType = this.project.media.type;
          this.imagePreview = this.project.media.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/projects" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.project);
      const id = this.roleId;
      this.$store
        .dispatch("projects/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          this.uploadProgress=-1;
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.uploadProgress = -1;
        });
    },
  },
};
</script>