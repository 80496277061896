import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/contact-us${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getAllSupport({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/get-all-support${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getContactUs({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/contact-infos`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-single-contact-info/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/about-us", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/about-us/${id}`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/about-us/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
