import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query
          ? `?${Object.entries(query)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : "";
        axiosInstance
          .get(`/disscusion-board${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getChat({ dispatch, commit }, query) {
      return new Promise((resolve, reject) => {
        const queryString = query
          ? `?${Object.entries(query)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : "";
        const id = query.id;
        axiosInstance
          .get(`/chat-disscusion/${id}${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/disscusion-board/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/disscusion-board", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/disscusion-board/${id}`, data)
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/disscusion-board/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getTmaBoards({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        let url = `/get-disscussion-tma`;
        if (id) {
          url += `/${id}`;
        }
        axiosInstance
          .get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
