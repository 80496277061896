<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/notifications" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Notification</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addRole">
                            <label>User Type <span class="text-danger">*</span></label>
                            <div class="row mb-3">
                                <div class="col-md-2">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="role" id="userRole"
                                            v-model="type" value="user" checked>
                                        <label class="form-check-label" for="userRole">
                                            User
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="role" id="adminRole"
                                            v-model="type" value="admin">
                                        <label v-if="role == 'super-admin'" class="form-check-label" for="adminRole">
                                            TMA's
                                        </label>
                                        <label v-else class="form-check-label" for="adminRole">
                                            Sub Admins
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="role == 'super-admin' && type == 'admin'">
                                <div class="d-flex flex-row align-items-center mb-2">
                                    <div>
                                        <label class="me-3 mb-0">Select TMA's</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" :checked="tmaIds?.length == tmas.length"
                                            style="margin: 0;" @change="toggleSelectAll" id="selectAll"
                                            class="form-check-input me-2">
                                        <label for="selectAll" class="mb-0">
                                            {{ (tmaIds && tmaIds.length === tmas.length) ? 'Unselect All' : 'Select All'
                                            }}
                                        </label>
                                    </div>
                                </div>
                                <multiselect class="mb-3" v-model="tmaIds" :options="tmas" :multiple="true"
                                    :close-on-select="false" placeholder="Select TMA's" :custom-label="tmaFormatedName"
                                    track-by="id">
                                </multiselect>
                            </div>

                            <div class="mb-3 d-flex align-items-center" v-if="type == 'user'">
                                <label class="me-2">Filter Notification</label>
                                <div class="form-check form-switch">
                                    <input v-model="isSendNotification" class="form-check-input" type="checkbox"
                                        id="sendNotificationYes" @change="handleNotificationChange" :true-value="1"
                                        :false-value="0" />
                                </div>
                            </div>
                            <label>Title <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" v-model="body.title"
                                required />
                            <label>Message <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" v-model="body.body" required />
                            <button type="button" id="openModel" class="d-none" data-bs-toggle="modal"
                                data-bs-target="#notificationModal">
                                Filter Notification
                            </button>
                            <div v-if="type == 'user'">
                                <notification_filter @save="filterResult"></notification_filter>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import notification_filter from "@/components/notification_filter.vue";
import roles from "@/store/modules/roles";
import notification from "@/store/modules/notification";

export default {
    components: {
        notification_filter
    },
    data() {
        return {
            loading: false,
            tmaIds: [],
            userIds: [],
            type: "user",
            adminIds: [],
            title: "",
            body: {
                title: "",
                body: "",
            },
            role: localStorage.getItem('role'),
            isSendNotification: false
        };
    },
    mounted() {
        // this.fetchAdmins();
        // this.fetchUsers();
        this.fetchTmas();
    },
    methods: {
        submit() {
            this.loading = true;
            const sender_id = localStorage.getItem('uid');
            const channel = (this.type === 'user') ? 'mobile' : "web";
            const typeNotification = (this.type === 'user') ? 'user' : 'admin';
            let selectedTmaIds = [];
            if (this.role == 'super-admin' && this.type == 'admin') {
                selectedTmaIds = this.tmaIds.map(tma => tma.id);
            }
            const data = {
                "sender_id": sender_id,
                "channel": channel,
                "type": typeNotification,
                "title": toRaw(this.body.title),
                "body": toRaw(this.body.body),
                "tmsIds": selectedTmaIds
            };
            this.$store
                .dispatch("notification/store", { data })
                .then((response) => {
                    this.loading = false;
                    this.$toast.show("Successfuly send", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.type = "user";
                    this.body.title = "";
                    this.body.body = "";
                    this.tmaIds = [];
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        fetchAdmins() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAll", { id })
                .then((response) => {
                    this.adminIds = response.data.map(item => item.id);
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },

        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.userIds = response.data.map(item => item.id);
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        handleNotificationChange() {
            if (this.isSendNotification) {
                const btn = document.getElementById("openModel");
                btn.click();
            }

        },

        fetchTmas() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAllTmas", { query: { id } })
                .then((response) => {
                    this.tmas = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        toggleSelectAll(event) {
            if (event.target.checked) {
                this.tmaIds = this.tmas;
            } else {
                this.tmaIds = [];
            }
        },
        tmaFormatedName(tma) {
            return tma.name + ' District:' + tma.district.name;
        },
        filterResult(result) {

        }
    },
};
</script>