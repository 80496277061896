<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/invest-with-tma" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Collaboration Request</h4>
                    </div>
                    <div class="card shadow-sm mb-4">
                        <div class="card-body">
                           
                            <div class="mb-3">
                                <label class="fw-bold">User Name:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.profile?.name ?? "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User Phone:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.phone_number || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User Email:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.email || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User Cnic:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.cnic || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User Occupation:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.profile?.occupation || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User District:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.district?.name || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">User Sub Division:</label>
                                <p class="border p-2 rounded">{{ requestService?.user?.tehsil?.name || "N/A" }}</p>
                            </div>

                        
                            <div class="mb-3">
                                <label class="fw-bold">Service Type:</label>
                                <p class="border p-2 rounded">{{ requestService?.service_type?.name || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">Co-Fund Amount:</label>
                                <p class="border p-2 rounded">{{ requestService?.co_fund_amount || "N/A" }}</p>
                            </div>

                            <div class="mb-3">
                                <label class="fw-bold">Co-Fund Detail:</label>
                                <p class="border p-2 rounded">{{ requestService?.co_fund_details || "N/A" }}</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
    data() {
        return {
            requestService: {
                user: {
                    email: '',
                    profile: {
                        'name': "",
                        'phone_number': "",
                        'occupation': "",
                        'uc': "",
                        'tahsil': "",
                        'district': "",
                    }
                },
                service_type: {
                    name: ''
                }
            },
            serviceId: null,
            loading: false,
            modules: [],
        };
    },
    mounted() {
        this.serviceId = this.$route.params.id;
        this.fetch(this.serviceId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("services/getSingle", { id })
                .then((response) => {
                    this.requestService = response.data;
                    console.log("tt", this.requestService);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        console.log("error", e.error);
                        // router.push({ path: "/invest-with-tma" });
                        // this.$toast.show("Page Not Found", {
                        //     theme: "outline",
                        //     position: "top",
                        //     type: "error",
                        //     duration: 2000,
                        // });
                    }
                });
        },
    },
};
</script>