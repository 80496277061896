<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <!-- <router-link to="/invest-with-tma" class="btn btn-sm btn-primary mb-3">Back</router-link> -->
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Contact Us</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addRole">

                            <label>Address <span class="text-danger">*</span></label>
                            <input type="text" v-model="aboutUs.address" class="form-control form-control-sm mb-3"
                                required />

                            <label>Contact Number<span class="text-danger">*</span></label>
                            <input type="text" @keypress="isNumber($event)" v-model="aboutUs.contact"
                                class="form-control form-control-sm mb-3" required />

                            <label>Location<span class="text-danger">*</span></label>
                            <input type="text" v-model="aboutUs.location" class="form-control form-control-sm mb-3"
                                required />


                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
    mixins: [isNumber],
    data() {
        return {
            aboutUs: {
                contact: null,
                address: null,
                location: null,
            },
            loading: false,
            modules: [],
            types: [],
            users: [],
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("aboutus/getContactUs")
                .then((response) => {
                    if (response.data) {
                        console.log("ds", response.data);
                        this.aboutUs = response.data;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        submit() {
            this.loading = true;
            const data = toRaw(this.aboutUs);
            console.log("ss", data);
            this.$store
                .dispatch("aboutus/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },

    },
};
</script>