<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Profile</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group" v-if="user.name">
                <label>TMA Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm mb-3" v-model="user.name" required />
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label>First Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input type="text" class="form-control form-control-sm mb-3" required v-model="user.f_name" />
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input type="text" class="form-control form-control-sm mb-3" v-model="user.l_name" />
                </div>
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control form-control-sm mb-3" v-model="user.phone"
                  @keypress="isNumber($event)" />
              </div>
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.email">
                  Email is required
                </span>
                <input type="email" class="form-control form-control-sm mb-3" required v-model="user.email" />
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      user: [],
      roles: [],
      errors: [],
      userId: null,
      loading: false,
    };
  },
  mounted() {
    this.userId = localStorage.getItem("uid");
    this.fetch(this.userId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          this.user = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      const id = this.userId;
      this.$store
        .dispatch("admins/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.data) {
            localStorage.setItem('tmaName', response.data);
          }
          localStorage.setItem('f_name', this.user?.f_name || '');
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          window.location.reload(); 
          this.errors = [];
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
  },
};
</script>
