<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">

        <div class="card">
          <div class="card-header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">App Users</h5>

                <a href="#" class="d-none" id="blockUserBtn" data-bs-toggle="modal"
                  data-bs-target="#totalParticipantModal">Block User</a>
              </div>
              <div class="col-6 text-end">
                <router-link v-if="permissions && permissions.includes('appUsers-Add')" to="/app-users/add"
                  class="btn btn-sm btn-primary mb-2 float-end">Add User</router-link>
              </div>
            </div>

          </div>
          <div class="card-body">
            <data-table :columns="columns" @blockUser="blockUser" :isblockUser="isblockUser" :isEdit="isEdit"
              :module="module" :isViewAble="isViewAble" :items="items" @deleted="fetch" @nextPage="nextPage"
              @clear="fetch" @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="totalParticipantModal" tabindex="-1" aria-labelledby="totalParticipantModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you want to {{ userAccount.status == 'active' ? 'block' : 'unblock' }} {{ userAccount?.profile?.name
              ?? ""}}?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">
              No
            </button>
            <button type="button" class="btn btn-sm btn-success" data-bs-dismiss="modal" @click="confirmBlockUser">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";



export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "profile",
          secondValue: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Cnic",
          value: "cnic",
        },
        {
          text: "Phone Number",
          value: "phone_number",
        },

      ],
      module: "users",
      permissions: null,
      isViewAble: true,
      isEdit: false,
      loading: false,
      isblockUser: false,
      userAccount: "",
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("appUsers-Edit")) ? true : false;
    this.isblockUser = (this.permissions && this.permissions.includes("blockUser")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("users/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    blockUser(user) {
      this.userAccount = user;
      const btn = document.getElementById("blockUserBtn");
      btn.click();
    },

    confirmBlockUser() {
      const id = this.userAccount.id;
      this.loading = true;
      this.$store
        .dispatch("users/blockUser", { id })
        .then((response) => {
          this.loading = false;
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    }
  },
};
</script>