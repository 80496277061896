import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";

import Dashboard from "@/views/dashboard/default";
import Login from "@/auth/login";
import VerfiyEmail from "@/auth/verify-email.vue";
import ResetPassword from "@/auth/reset_password.vue";

import RoleIndex from "@/views/role/index";
import AddRole from "@/views/role/add";
import EditRole from "@/views/role/edit";

import AdminIndex from "@/views/admin/index";
import AddAdmin from "@/views/admin/add";
import EditAdmin from "@/views/admin/edit";
import AdminProfile from "@/views/admin/profile";
import AdminChangePassword from "@/views/admin/changePassword";

import CampaignTypesIndex from "@/views/campaign_managment/campaigntypes/index";
import CampaignTypesAdd from "@/views/campaign_managment/campaigntypes/add";
import CampaignTypesEdit from "@/views/campaign_managment/campaigntypes/edit";

import CampaignsIndex from "@/views/campaign_managment/campaigns/index";
import CampaignsEdit from "@/views/campaign_managment/campaigns/edit";
import CampaignsAdd from "@/views/campaign_managment/campaigns/add";
import CampaignsRegisteredUsers from "@/views/campaign_managment/campaigns/users";

import ProjectsIndex from "@/views/projects/index";
import ProjectsAdd from "@/views/projects/add";
import ProjectsEdit from "@/views/projects/edit";

import TeamIndex from "@/views/managmentTeam/index";
import TeamAdd from "@/views/managmentTeam/add";
import TeamEdit from "@/views/managmentTeam/edit";

import TmaVideoIndex from "@/views/aboutTmaVideos/index";
import TmaVideoAdd from "@/views/aboutTmaVideos/add";
import TmaVideoEdit from "@/views/aboutTmaVideos/edit";

import ComplaintTypesIndex from "@/views/Complaint/ComplaintTypes/index";
import ComplaintTypesAdd from "@/views/Complaint/ComplaintTypes/add";
import ComplaintTypesEdit from "@/views/Complaint/ComplaintTypes/edit";

import ComplaintsIndex from "@/views/Complaint/Complaints/index";
import ComplaintsEdit from "@/views/Complaint/Complaints/edit";
import ComplaintsAdd from "@/views/Complaint/Complaints/add";

import NewsIndex from "@/views/news/index";
import NewsAdd from "@/views/news/add";
import NewsEdit from "@/views/news/edit";

import PressIndex from "@/views/press/index";
import PressAdd from "@/views/press/add";
import PressEdit from "@/views/press/edit";

import EventsIndex from "@/views/events/index";
import EventsAdd from "@/views/events/add";
import EventsEdit from "@/views/events/edit";

import ServiceTypeIndex from "@/views/serviceTypes/index";
import ServiceTypeAdd from "@/views/serviceTypes/add";
import ServiceTypeEdit from "@/views/serviceTypes/edit";

import ServiceIndex from "@/views/services/index";
import ServiceEdit from "@/views/services/edit";
import ServiceAdd from "@/views/services/add";

import TmaInvestIndex from "@/views/investWithTma/index";
import TmaInvestEdit from "@/views/investWithTma/edit";
import TmaInvestAdd from "@/views/investWithTma/add";

import StoriesIndex from "@/views/successStories/index";
import StoriesAdd from "@/views/successStories/add";
import StoriesEdit from "@/views/successStories/edit";

import ActiveCitizenIndex from "@/views/activeCitizen/index";
import ActiveCitizenAdd from "@/views/activeCitizen/add";
import ActiveCitizenEdit from "@/views/activeCitizen/edit";

import ResolutionIndex from "@/views/resolution/index";
import ResolutionAdd from "@/views/resolution/add";
import ResolutionEdit from "@/views/resolution/edit";

import DisscussionBoard from "@/views/disscusionboard/add";
import DisscussionBoardIndex from "@/views/disscusionboard/index";
import DisscussionBoardEdit from "@/views/disscusionboard/edit";
import DisscussionBoardView from "@/views/disscusionboard/view";

import SurveyAdd from "@/views/survey/add";
import SurveyIndex from "@/views/survey/index";
import SurveyEdit from "@/views/survey/edit";
import SurveyView from "@/views/survey/view";

import PollAdd from "@/views/poll/add";
import PollIndex from "@/views/poll/index";
import PollEdit from "@/views/poll/edit";
import PollView from "@/views/poll/view";

import PartnerWithTMA from "@/views/partnerwithtma/add";
import PartnerWithTMAView from "@/views/partnerwithtma/edit";
import PartnerWithTMAIndex from "@/views/partnerwithtma/index";

import AboutUS from "@/views/aboutus/add";
import ContactUs from "@/views/aboutus/index";
import ContactUsView from "@/views/aboutus/view";

import AppUsers from "@/views/users/index";
import AppUsersAdd from "@/views/users/add";
import AppUsersEdit from "@/views/users/edit";
import AppUsersView from "@/views/users/view";

import Notification from "@/views/notification/add";
import NotificationIndex from "@/views/notification/index";

import FeedbackCategory from "@/views/feedbackCategory/index";

import UserFeedback from "@/views/userFeedbacks/index";
import SupportIndex from "@/views/support/index";

import RegisterWithTMA from "@/views/registerWithTMA/index";

import AdvancedSearchIndex from "@/views/advanceSearch/index";

import DistrictIndex from "@/views/districts/index";
import DistrictAdd from "@/views/districts/add";
import DistrictEdit from "@/views/districts/edit";

import ProfessionIndex from "@/views/profession/index";
import ProfessionAdd from "@/views/profession/add";
import ProfessionEdit from "@/views/profession/edit";

import TehsilIndex from "@/views/tehsil/index";
import TehsilAdd from "@/views/tehsil/add";
import TehsilEdit from "@/views/tehsil/edit";

import AreaIndex from "@/views/area/index";
import AreaAdd from "@/views/area/add";
import AreaEdit from "@/views/area/edit";

import TMAEventIndex from "@/views/tmaEvents/index.vue";
import TMAEventAdd from "@/views/tmaEvents/add.vue";
import TMAEventEdit from "@/views/tmaEvents/edit.vue";

import TmaIndex from "@/views/tma/index";
import TmaAdd from "@/views/tma/add";
import TmaEdit from "@/views/tma/edit";
import TmaProfile from "@/views/tma/profile";
import TmaChangePassword from "@/views/tma/changePassword";

import WebContent from "@/views/webContent/index";

import AddTestimonial from "@/views/testimonials/add";
import EditTestimonials from "@/views/testimonials/edit";

import Reporting from "@/views/reporting/index";

import Gallary from "@/views/gallary/index";
import GallaryMedia from "@/views/gallary/gallary_media";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: Body,

    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        name: "Dashboard",
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: "verifyEmail",
        component: VerfiyEmail,
        meta: {
          public: true,
        },
      },
      {
        path: "resetPassword",
        component: ResetPassword,
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/roles",
    component: Body,
    children: [
      {
        path: "/roles",
        component: RoleIndex,
        meta: {
          module: "role-View",
        },
      },
      {
        path: "/roles/add",
        component: AddRole,
        meta: {
          module: "role-Add",
        },
      },
      {
        path: "/roles/edit/:id",
        component: EditRole,
        meta: {
          module: "role-Add",
        },
      },
    ],
  },
  {
    path: "/admins",
    component: Body,
    children: [
      {
        path: "/admins/profile",
        component: AdminProfile,
        meta: {
          module: "forAll",
        },
      },
      {
        path: "/admins/changePassword/:id?",
        component: AdminChangePassword,
        meta: {
          module: "forAll",
        },
      },
      {
        path: "/admins",
        component: AdminIndex,
        meta: {
          module: "admin-View",
        },
      },
      {
        path: "/admins/add",
        component: AddAdmin,
        meta: {
          module: "admin-Add",
        },
      },
      {
        path: "/admins/edit/:id",
        component: EditAdmin,
        meta: {
          module: "admin-Edit",
        },
      },
    ],
  },

  {
    path: "/tma",
    component: Body,
    children: [
      {
        path: "/admins/profile",
        component: AdminProfile,
        meta: {
          module: "forAll",
        },
      },
      {
        path: "/tma/changePassword/:id?",
        component: TmaChangePassword,
        meta: {
          module: "forAll",
        },
      },
      {
        path: "/tma",
        component: TmaIndex,
        meta: {
          module: "tma-admins",
        },
      },
      {
        path: "/tma/add",
        component: TmaAdd,
        meta: {
          module: "tma-admins",
        },
      },
      {
        path: "/tma/edit/:id",
        component: TmaEdit,
        meta: {
          module: "tma-admins",
        },
      },
    ],
  },

  {
    path: "/campaign-types",
    component: Body,
    children: [
      {
        path: "/campaign-types",
        component: CampaignTypesIndex,
        meta: {
          module: "campaignType-View",
        },
      },
      {
        path: "/campaign-types/add",
        component: CampaignTypesAdd,
        meta: {
          module: "campaignType-Add",
        },
      },
      {
        path: "/campaigntypes/edit/:id",
        component: CampaignTypesEdit,
        meta: {
          module: "campaignType-Edit",
        },
      },
    ],
  },

  {
    path: "/campaigns",
    component: Body,
    children: [
      {
        path: "/campaigns",
        component: CampaignsIndex,
        meta: {
          module: "campaign-View",
        },
      },
      {
        path: "/campaigns/add",
        component: CampaignsAdd,
        meta: {
          module: "campaign-Add",
        },
      },
      {
        path: "/campaigns/edit/:id",
        component: CampaignsEdit,
        meta: {
          module: "campaign-Edit",
        },
      },
      {
        path: "/campaigns/users/:id",
        component: CampaignsRegisteredUsers,
        meta: {
          module: "campaign-View",
        },
      },
    ],
  },
  {
    path: "/projects",
    component: Body,
    children: [
      {
        path: "/projects",
        component: ProjectsIndex,
        meta: {
          module: "project-View",
        },
      },
      {
        path: "/project/add",
        component: ProjectsAdd,
        meta: {
          module: "project-Add",
        },
      },
      {
        path: "/projects/edit/:id",
        component: ProjectsEdit,
        meta: {
          module: "project-Edit",
        },
      },
    ],
  },
  {
    path: "/managment-team",
    component: Body,
    children: [
      {
        path: "/managment-team",
        component: TeamIndex,
        meta: {
          module: "tmaTeam-View",
        },
      },
      {
        path: "/managment-team/add",
        component: TeamAdd,
        meta: {
          module: "tmaTeam-Add",
        },
      },
      {
        path: "/managmentTeam/edit/:id",
        component: TeamEdit,
        meta: {
          module: "tmaTeam-Edit",
        },
      },
    ],
  },
  {
    path: "/about-tma-videos",
    component: Body,
    children: [
      {
        path: "/about-tma-videos",
        component: TmaVideoIndex,
        meta: {
          module: "tmaVideos-View",
        },
      },
      {
        path: "/about-tma-video/add",
        component: TmaVideoAdd,
        meta: {
          module: "tmaVideos-Add",
        },
      },
      {
        path: "/aboutTmaVideos/edit/:id",
        component: TmaVideoEdit,
        meta: {
          module: "tmaVideos-Edit",
        },
      },
    ],
  },
  {
    path: "/complaint-types",
    component: Body,
    children: [
      {
        path: "/complaint-types",
        component: ComplaintTypesIndex,
        meta: {
          module: "complainType-View",
        },
      },
      {
        path: "/complaint-types/add",
        component: ComplaintTypesAdd,
        meta: {
          module: "complainType-Add",
        },
      },
      {
        path: "/ComplaintTypes/edit/:id",
        component: ComplaintTypesEdit,
        meta: {
          module: "complainType-Edit",
        },
      },
    ],
  },
  {
    path: "/complaints",
    component: Body,
    children: [
      {
        path: "/complaints",
        component: ComplaintsIndex,
        meta: {
          module: "complain-View",
        },
      },
      {
        path: "/complaints/view/:id",
        component: ComplaintsEdit,
        meta: {
          module: "complain-View",
        },
      },
      {
        path: "/complaint/add",
        component: ComplaintsAdd,
      },
    ],
  },
  {
    path: "/news",
    component: Body,
    children: [
      {
        path: "/news",
        component: NewsIndex,
        meta: {
          module: "newsAndEvent-View",
        },
      },
      {
        path: "/news/add",
        component: NewsAdd,
        meta: {
          module: "newsAndEvent-Add",
        },
      },
      {
        path: "/news/edit/:id",
        component: NewsEdit,
        meta: {
          module: "newsAndEvent-Edit",
        },
      },
    ],
  },
  {
    path: "/press",
    component: Body,
    children: [
      {
        path: "/press",
        component: PressIndex,
        meta: {
          module: "press-View",
        },
      },
      {
        path: "/press/add",
        component: PressAdd,
        meta: {
          module: "press-Add",
        },
      },
      {
        path: "/press/edit/:id",
        component: PressEdit,
        meta: {
          module: "press-Edit",
        },
      },
    ],
  },
  {
    path: "/events",
    component: Body,
    children: [
      {
        path: "/events",
        component: EventsIndex,
        meta: {
          module: "event-View",
        },
      },
      {
        path: "/event/add",
        component: EventsAdd,
        meta: {
          module: "event-Add",
        },
      },
      {
        path: "/event/edit/:id",
        component: EventsEdit,
        meta: {
          module: "event-Edit",
        },
      },
    ],
  },

  {
    path: "/service-type",
    component: Body,
    children: [
      {
        path: "/service-types",
        component: ServiceTypeIndex,
        meta: {
          module: "serviceType-View",
        },
      },
      {
        path: "/service-type/add",
        component: ServiceTypeAdd,
        meta: {
          module: "serviceType-Add",
        },
      },
      {
        path: "/serviceTypes/edit/:id",
        component: ServiceTypeEdit,
        meta: {
          module: "serviceType-Edit",
        },
      },
    ],
  },
  {
    path: "/request-services",
    component: Body,
    children: [
      {
        path: "/request-services",
        component: ServiceIndex,
        meta: {
          module: "collaboration-View",
        },
      },
      {
        path: "/request-service/add",
        component: ServiceAdd,
      },
      {
        path: "/services/edit/:id",
        component: ServiceEdit,
        meta: {
          module: "collaboration-View",
        },
      },
    ],
  },
  {
    path: "/invest-with-tma",
    component: Body,
    children: [
      {
        path: "/invest-with-tma",
        component: TmaInvestIndex,
        meta: {
          module: "collaboration-View",
        },
      },
      {
        path: "/investWithTma/view/:id",
        component: TmaInvestEdit,
        meta: {
          module: "collaboration-View",
        },
      },
      {
        path: "/invest-with-tma/add",
        component: TmaInvestAdd,
      },
    ],
  },
  {
    path: "/success-stories",
    component: Body,
    children: [
      {
        path: "/success-stories",
        component: StoriesIndex,
        meta: {
          module: "successStory-View",
        },
      },
      {
        path: "/success-stories/add",
        component: StoriesAdd,
        meta: {
          module: "successStory-Add",
        },
      },
      {
        path: "/successStories/edit/:id",
        component: StoriesEdit,
        meta: {
          module: "successStory-Edit",
        },
      },
    ],
  },
  {
    path: "/active-citizen",
    component: Body,
    children: [
      {
        path: "/active-citizen",
        component: ActiveCitizenIndex,
        meta: {
          module: "activeCitizen-View",
        },
      },
      {
        path: "/active-citizen/add",
        component: ActiveCitizenAdd,
        meta: {
          module: "activeCitizen-Add",
        },
      },
      {
        path: "/ActiveCitizen/edit/:id",
        component: ActiveCitizenEdit,
        meta: {
          module: "activeCitizen-Edit",
        },
      },
    ],
  },
  {
    path: "/districts",
    component: Body,
    children: [
      {
        path: "/districts",
        component: DistrictIndex,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/districts/add",
        component: DistrictAdd,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/districts/edit/:id",
        component: DistrictEdit,
        meta: {
          module: "setting",
        },
      },
    ],
  },
  {
    path: "/professions",
    component: Body,
    children: [
      {
        path: "/professions",
        component: ProfessionIndex,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/professions/add",
        component: ProfessionAdd,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/professions/edit/:id",
        component: ProfessionEdit,
        meta: {
          module: "setting",
        },
      },
    ],
  },
  {
    path: "/tehsil",
    component: Body,
    children: [
      {
        path: "/tehsil",
        component: TehsilIndex,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/tehsil/add",
        component: TehsilAdd,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/tehsil/edit/:id",
        component: TehsilEdit,
        meta: {
          module: "setting",
        },
      },
    ],
  },
  {
    path: "/area",
    component: Body,
    children: [
      {
        path: "/area",
        component: AreaIndex,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/area/add",
        component: AreaAdd,
        meta: {
          module: "setting",
        },
      },
      {
        path: "/area/edit/:id",
        component: AreaEdit,
        meta: {
          module: "setting",
        },
      },
    ],
  },
  {
    path: "/discussion-board",
    component: Body,
    children: [
      {
        path: "/discussion-board/add",
        component: DisscussionBoard,
        meta: {
          module: "disscussionBoard-Add",
        },
      },
      {
        path: "/discussion-board",
        component: DisscussionBoardIndex,
        meta: {
          module: "disscussionBoard-View",
        },
      },
      {
        path: "/disscusionBoard/edit/:id",
        component: DisscussionBoardEdit,
        meta: {
          module: "disscussionBoard-Edit",
        },
      },
      {
        path: "/disscusionBoard/disscusion/:id",
        component: DisscussionBoardView,
        meta: {
          module: "disscussionBoard-View",
        },
      },
    ],
  },
  {
    path: "/survey",
    component: Body,
    children: [
      {
        path: "/survey/add",
        component: SurveyAdd,
        meta: {
          module: "survey-Add",
        },
      },
      {
        path: "/survey",
        component: SurveyIndex,
        meta: {
          module: "survey-View",
        },
      },
      {
        path: "/survey/edit/:id",
        component: SurveyEdit,
        meta: {
          module: "survey-Edit",
        },
      },
      {
        path: "/survey/disscusion/:id",
        component: SurveyView,
        meta: {
          module: "survey-View",
        },
      },
    ],
  },
  {
    path: "/poll",
    component: Body,
    children: [
      {
        path: "/poll/add",
        component: PollAdd,
        meta: {
          module: "poll-Add",
        },
      },
      {
        path: "/poll",
        component: PollIndex,
        meta: {
          module: "poll-View",
        },
      },
      {
        path: "/poll/edit/:id",
        component: PollEdit,
        meta: {
          module: "poll-Edit",
        },
      },
      {
        path: "/poll/disscusion/:id",
        component: PollView,
        meta: {
          module: "poll-View",
        },
      },
    ],
  },
  {
    path: "/partnerwithtma",
    component: Body,
    children: [
      {
        path: "/partnerwithtma/add",
        component: PartnerWithTMA,
      },
      {
        path: "/partnerwithtma/view/:id",
        component: PartnerWithTMAView,
        meta: {
          module: "partnershipwithtma",
        },
      },
      {
        path: "/partnerwithtma",
        component: PartnerWithTMAIndex,
        meta: {
          module: "partnershipwithtma",
        },
      },
    ],
  },

  {
    path: "/register-with-tma",
    component: Body,
    children: [
      {
        path: "/register-with-tma",
        component: RegisterWithTMA,
        meta: {
          module: "registerWithTma-View",
        },
      },
    ],
  },

  {
    path: "/advance-search",
    component: Body,
    children: [
      {
        path: "/advance-search",
        component: AdvancedSearchIndex,
        meta: {
          module: "advanceSearch-View",
        },
      },
    ],
  },

  {
    path: "/contact-us",
    component: Body,
    children: [
      {
        path: "/aboutus/add",
        component: AboutUS,
        meta: {
          module: "contactUs-Add",
        },
      },
      {
        path: "/contact-us",
        component: ContactUs,
        meta: {
          module: "aboutUs-View",
        },
      },
      {
        path: "/contactus/view/:id",
        component: ContactUsView,
        meta: {
          module: "aboutUs-View",
        },
      },
    ],
  },

  {
    path: "/testimonial/add",
    component: Body,
    children: [
      {
        path: "/testimonial/add",
        component: AddTestimonial,
        meta: {
          module: "testimonial-add",
        },
      },
      {
        path: "/web/edit/:id",
        component: EditTestimonials,
        meta: {
          module: "testimonial-edit",
        },
      },
    ],
  },

  {
    path: "/web-content/add",
    component: Body,
    children: [
      {
        path: "/web-content/add",
        component: WebContent,
        meta: {
          module: "web-content",
        },
      },
    ],
  },

  {
    path: "/app-users",
    component: Body,
    children: [
      {
        path: "/app-users",
        component: AppUsers,
        meta: {
          module: "appUsers-View",
        },
      },
      {
        path: "/app-users/add",
        component: AppUsersAdd,
        meta: {
          module: "appUsers-Add",
        },
      },
      {
        path: "/users/edit/:id",
        component: AppUsersEdit,
        meta: {
          module: "appUsers-Edit",
        },
      },
      {
        path: "/users/view/:id",
        component: AppUsersView,
        meta: {
          module: "appUsers-View",
        },
      },
    ],
  },
  {
    path: "/notifications/add",
    component: Body,
    children: [
      {
        path: "/notifications/add",
        component: Notification,
        meta: {
          module: "notification-View",
        },
      },
      {
        path: "/notifications",
        component: NotificationIndex,
        meta: {
          module: "notification-View",
        },
      },
    ],
  },
  {
    path: "/setting/modules/feedback",
    component: Body,
    children: [
      {
        path: "/setting/modules/feedback",
        component: FeedbackCategory,
        meta: {
          module: "forAll",
        },
      },
    ],
  },
  {
    path: "/user/feedback",
    component: Body,
    children: [
      {
        path: "/user/feedback",
        component: UserFeedback,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    children: [
      {
        path: "/support",
        component: SupportIndex,
        meta: {
          module: "support",
        },
      },
    ],
  },
  {
    path: "/user/feedback",
    component: Body,
    children: [
      {
        path: "/user/feedback",
        component: UserFeedback,
        meta: {
          module: "feedback",
        },
      },
    ],
  },
  {
    path: "/reporting",
    component: Body,
    children: [
      {
        path: "/reporting",
        component: Reporting,
        meta: {
          module: "reporting",
        },
      },
    ],
  },
  {
    path: "/gallary",
    component: Body,
    children: [
      {
        path: "/gallary",
        component: Gallary,
        meta: {
          module: "gallary-View",
        },
      },
      {
        path: "/gallary-media/:id",
        component: GallaryMedia,
        meta: {
          module: "gallary-View",
        },
      },
    ],
  },
  {
    path: "/tma_events",
    component: Body,
    children: [
      {
        path: "/tma_events",
        component: TMAEventIndex,
        meta: {
          module: "event_tma",
        },
      },
      {
        path: "/tma_events/edit/:id",
        component: TMAEventEdit,
        meta: {
          module: "event_tma",
        },
      },
      {
        path: "/tma_events/add",
        component: TMAEventAdd,
        meta: {
          module: "event_tma",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

const permissions = localStorage.getItem("permissions");

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  if (!to.meta?.public && !isAuthenticated && to.name !== "Login 1") {
    next({ name: "Login 1" });
  } else if (to.meta?.module == "forAll") {
    next();
  } else if (
    isAuthenticated &&
    !permissions.includes(to.meta?.module) &&
    to.name !== "Dashboard"
  ) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = !!localStorage.getItem("token");
//   if (!to.meta?.public && !isAuthenticated) {
//     next({ name: "Login 1" });
//   } else {
//     next();
//   }
// });

export default router;
