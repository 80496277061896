<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row justify-content-center">
      <div class="col-md-8 mt-5">
        <router-link to="/partnerwithtma" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header text-center">
            <h4 class="card-title mb-0">Service Request Details</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- User Name -->
              <div class="col-md-6 mb-3">
                <strong>Name:</strong>
                <p class="text-muted mb-0">
                  {{ requestService?.user?.profile?.name }}
                </p>
              </div>
              <!-- Phone -->
              <div class="col-md-6 mb-3">
                <strong>Phone:</strong>
                <p class="text-muted mb-0">
                  {{ requestService?.user?.phone_number }}
                </p>
              </div>
              <!-- User Email -->
              <div class="col-md-6 mb-3">
                <strong>Email:</strong>
                <p class="text-muted mb-0">{{ requestService?.user?.email }}</p>
              </div>
              <div class="col-md-6 mb-3">
                <strong>Cnic:</strong>
                <p class="text-muted mb-0">{{ requestService?.user?.cnic }}</p>
              </div>
              <div class="col-md-6 mb-3">
                <strong>Cnic:</strong>
                <p class="text-muted mb-0">{{ requestService?.user?.cnic }}</p>
              </div>

              <div class="col-md-6 mb-3">
                <strong>Gender:</strong>
                <p class="text-muted mb-0">
                  {{ requestService?.user?.profile?.gender }}
                </p>
              </div>

              <!-- Service Type -->
              <div class="col-md-6 mb-3">
                <strong>Service Type:</strong>
                <p class="text-muted mb-0">
                  {{ requestService?.service_type?.name }}
                </p>
              </div>
              <!-- Co-Fund Amount -->
              <div class="col-md-6 mb-3">
                <strong>Co-Fund Amount:</strong>
                <p class="text-muted mb-0">
                  {{ requestService?.co_fund_amount }}
                </p>
              </div>
            </div>
            <a :href="gmailLink" target="_blank" class="btn btn-primary mt-4">
              Send Email
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  data() {
    return {
      requestService: {
        user: {
          email: "",
          profile: {
            name: "",
            phone_number: "",
            occupation: "",
            uc: "",
            tahsil: "",
            district: "",
          },
        },
        service_type: {
          name: "",
        },
      },
      serviceId: null,
      loading: false,
      modules: [],
    };
  },
  computed: {
    gmailLink() {
      const email = this.requestService.user.email;
      const subject=`Partnership Request on ${this. requestService.service_type.name}`
      return `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}`;
    },
  },
  mounted() {
    this.serviceId = this.$route.params.id;
    this.fetch(this.serviceId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("investWithTma/getSingle", { id })
        .then((response) => {
          this.requestService = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/partnerwithtma" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
  },
};
</script>
      