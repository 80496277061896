import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    store({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/store-notification", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getAll({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/notification/web/admin/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`/read-notification`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getAllSenderNotifications({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query
        ? `?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
        axiosInstance
          .get(`/get-all-sender-notification${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
