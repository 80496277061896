<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/campaign-types" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Campaign types</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Name<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="role.name"
              />

              <label>Description</label>
              <QuillEditor  v-model="role.description" :editorHeight="'300px'" :editorWidth="'800px'" />

              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      role: {
        name: "",
      },
      roleId: null,
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    this.fetch(this.roleId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("campaigntypes/getSingle", { id })
        .then((response) => {
          this.role = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {      
            router.push({ path: "/campaign-types" });
            this.$toast.show("Page Not Found", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 2000,
          });          
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      const id = this.roleId;
      this.$store
        .dispatch("campaigntypes/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
  