
import axiosInstance from "@/helpers/axios";
import router from "@/router";

export default {
  
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async uploadFileInChunks(
      file,
      chunkSizeMB = 2,
      maxRetries = 3,
      folder = "users",
      progressCallback 
    ) {
      const chunkSize = chunkSizeMB * 1024 * 1024;
      const totalChunks = Math.ceil(file.size / chunkSize);
      let currentChunkIndex = 0;
      const chunkRetries = {}; 
      const uploadedChunksString = localStorage.getItem(file.name);
      const uploadedChunks = uploadedChunksString ? JSON.parse(uploadedChunksString) : [];
      currentChunkIndex = uploadedChunks.length; 
      let filePath;
      const uploadChunk = async (chunk, index) => {
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunkIndex", index.toString());
        formData.append("totalChunks", totalChunks.toString());
        formData.append("fileName", file.name);
        formData.append("folder", folder);
        try {
          await axiosInstance
            .post(`/upload-media`, formData)
            .then((response) => {
              filePath = response.data.file;
            })
            .catch((e) => {
              const log = {
                message: e.message,
              };
              generateLog(log);
            });
          uploadedChunks.push(index);
          localStorage.setItem(file.name, JSON.stringify(uploadedChunks));
          return true; 
        } catch (error) {
          console.error("Error uploading chunk", index, error);
          chunkRetries[index] = (chunkRetries[index] || 0) + 1;
          if (chunkRetries[index] <= maxRetries) {
            console.log(`Retrying chunk ${index} (Attempt ${chunkRetries[index]})`);
            return await uploadChunk(chunk, index);
          } 
          return false;
        }
      };
      while (currentChunkIndex < totalChunks) {
        const start = currentChunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const success = await uploadChunk(chunk, currentChunkIndex);
        if (!success) {
          alert("Unable Save File");
          return;
        }
        const progressPercent = parseFloat((((currentChunkIndex + 1) / totalChunks) * 100).toFixed(2));
        if (progressCallback) {
          await progressCallback(progressPercent);
        }
        currentChunkIndex++;
      }
      localStorage.removeItem(file.name);
      return filePath;
    },

    async uploadFileInChunksInGallary(
      file,
      chunkSizeMB = 2,
      maxRetries = 3,
      folder = "users",
      progressCallback 
    ) {
      const chunkSize = chunkSizeMB * 1024 * 1024;
      const totalChunks = Math.ceil(file.size / chunkSize);
      let currentChunkIndex = 0;
      const chunkRetries = {}; 
      const uploadedChunksString = localStorage.getItem(file.name);
      const uploadedChunks = uploadedChunksString ? JSON.parse(uploadedChunksString) : [];
      currentChunkIndex = uploadedChunks.length; 
      let filePath;
      const uploadChunk = async (chunk, index) => {
        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunkIndex", index.toString());
        formData.append("totalChunks", totalChunks.toString());
        formData.append("fileName", file.name);
        formData.append("folder", folder);
        try {
          await axiosInstance
            .post(`/upload-media`, formData)
            .then((response) => {
              filePath = response.data.file;
            })
            .catch((e) => {
              const log = {
                message: e.message,
              };
              generateLog(log);
            });
          uploadedChunks.push(index);
          localStorage.setItem(file.name, JSON.stringify(uploadedChunks));
          return true; 
        } catch (error) {
          console.error("Error uploading chunk", index, error);
          chunkRetries[index] = (chunkRetries[index] || 0) + 1;
          if (chunkRetries[index] <= maxRetries) {
            console.log(`Retrying chunk ${index} (Attempt ${chunkRetries[index]})`);
            return await uploadChunk(chunk, index);
          } 
          return false;
        }
      };
      while (currentChunkIndex < totalChunks) {
        const start = currentChunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const success = await uploadChunk(chunk, currentChunkIndex);
        if (!success) {

          return "failedFileUpload";
        }
        const progressPercent = parseFloat((((currentChunkIndex + 1) / totalChunks) * 100).toFixed(2));
        if (progressCallback) {
          await progressCallback(progressPercent);
        }
        currentChunkIndex++;
      }
      localStorage.removeItem(file.name);
      return filePath;
    }
  },
};
