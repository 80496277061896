<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/app-users" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add User</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addUser">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Full Name <span class="text-danger">*</span></label>
                                    <span class="text-danger d-block" v-if="errors.fname">
                                        {{ errors.fname && errors.fname.length > 0 ? errors.fname[0] : '' }}
                                    </span>
                                    <span class="text-danger d-block" v-if="errors.f_name">
                                        First Name is required
                                    </span>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="admin.name" />
                                </div>
                                <div class="col-md-6">
                                    <label>Occupation</label>
                                    <input type="text" class="form-control form-control-sm mb-3"
                                        v-model="admin.occupation" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.cnic">
                                    {{ errors.cnic && errors.cnic.length > 0 ? errors.cnic[0] :
                                        '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" maxlength="13" minlength="13" required />
                            </div>
                            <div class="form-group">
                                <label>Phone <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.phone_number">
                                    {{ errors.phone_number && errors.phone_number.length > 0 ? errors.phone_number[0] :
                                        '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3"
                                    v-model="admin.phone_number" maxlength="11" minlength="11"
                                    @keypress="isNumber($event)" required />
                            </div>

                            <div class="form-group">
                                <label>Address <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.address">
                                    {{ errors.address && errors.address.length > 0 ? errors.address[0] :
                                        '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.address"
                                    required />
                            </div>
                            <div class="form-group">
                                <label>Tehsil <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" v-model="admin.area_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="area in areas" :value="area.id">
                                        {{ area.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <label>Date of birth <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-sm mb-3" required
                                        v-model="admin.date_of_birth" />
                                </div>
                                <div class="col-md-12">
                                    <label>Gender <span class="text-danger">*</span></label>
                                    <div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="male"
                                                value="Male" v-model="admin.gender" required>
                                            <label class="form-check-label" for="male">Male</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="female"
                                                value="Female" v-model="admin.gender" required>
                                            <label class="form-check-label" for="female">Female</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="gender" id="other"
                                                value="Other" v-model="admin.gender" required>
                                            <label class="form-check-label" for="other">Other</label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>
                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" />
                            </div>
                            <div class="form-group">
                                <label>Password <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.password">
                                    {{ errors.password && errors.password.length > 0 ? errors.password[0] : '' }}
                                </span>
                                <input type="password" class="form-control form-control-sm mb-3" required
                                    v-model="admin.password" />
                            </div>

                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
    mixins: [isNumber],
    data() {
        return {
            admin: {
                name: "",
                occupation: "",
                phone_number: "",
                email: "",
                password: "",
                date_of_birth: "",
                gender: "",
                cnic: "",
                district_id: "",
                tehsil_id: "",
                area_id: "",
                address: ""
            },
            areas: [],
            errors: [],
            loading: false,
        };
    },

    mounted() {
        this.fetchDistricts();
    },

    methods: {
        submit() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.admin);
            data.type = "web";
            this.$store
                .dispatch("users/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success == true) {
                        this.$toast.show("Successfuly added.", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.admin).forEach((key) => (this.admin[key] = ""));
                        this.fetchDistricts();
                        this.errors = [];
                    } else {
                        this.errors = response.error;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/showDistrictData")
                .then((response) => {
                    this.admin.district_id = response.data.district_id;
                    this.admin.tehsil_id = response.data.tehsil_id;
                    this.areas = response.data.areas;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },



    },
};
</script>