<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">




            <div class="col-md-12 mt-5">
                <router-link v-if="permissions && permissions.includes('disscussionBoard-Add')"
                    to="/discussion-board/add" class="btn btn-sm btn-primary mb-2 float-end">Add
                </router-link>
            </div>
            <div class="col-md-12" v-if="disscusionBoard.type == 'chat'">

                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">Comments</h5>

                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" :items="items" @deleted="fetch"
                            @nextPage="nextPage" @clear="fetch" @search="fetch"></data-table>

                    </div>
                </div>


            </div>
            <div class="col-md-12" v-if="disscusionBoard.type == 'poll'">
                <h2>Poll Results</h2>
                <div class="col-md-6" v-for="pollResult in pollResults" :key="pollResult.question_id">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{ pollResult.question_text }}</h5>
                            <ul class="list-group">
                                <li v-for="option in pollResult.options" :key="option.option_id"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    {{ option.option_text }}
                                    <span class="badge badge-primary badge-pill">{{ option.percentage }}%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Poll Results</h5>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columnsForPoll" :module="module" :items="items" @deleted="fetch"
                            @nextPage="nextPage" @clear="fetch" @search="fetch"></data-table>
                    </div>
                </div>







                <!-- <div>
                    {{ pollResult.question_text }}
                </div>
                <div>
                    <ul>
                        <li v-for="option in pollResult.options" :key="option.option_id">
                            {{ option.option_text }} - {{ option.percentage }}%
                        </li>
                    </ul>
                </div> -->

            </div>
            <div class="col-md-12" v-if="disscusionBoard.type == 'survey'">
                <h2>Survey Result</h2>
                <div class="col-md-6" v-for="pollResult in pollResults" :key="pollResult.question_id">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{ pollResult.question_text }}</h5>
                            <ul class="list-group">
                                <li v-for="option in pollResult.options" :key="option.option_id"
                                    class="list-group-item d-flex justify-content-between align-items-center">
                                    {{ option.option_text }}
                                    <span class="badge badge-primary badge-pill">{{ option.percentage }}%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Survey Results</h5>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columnsForSurvey" :isSurveyResult="isSurveyResult"
                            @open-survey="handleSurveyResult" :module="module" :items="items"
                            @deleted="fetch"></data-table>
                    </div>
                </div>


            </div>


        </div>

        <div ref="totalParticipantModal" class="modal fade" id="totalParticipantModal" tabindex="-1"
            aria-labelledby="totalParticipantModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-center mb-5 mt-5">
                            <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                                Survey Result
                            </h5>
                        </div>
                        <data-table :columns="columnsForSurveyAnswers" :module="module" :items="surveyAnswers"
                            @deleted="fetch"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import router from "@/router";
import axiosInstance from "@/helpers/axios";
import { loader } from "@/data/uikits/data";





export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            disscusionBoard: [],
            surveyAnswers: [],
            columns: [
                {
                    text: "User Email",
                    value: "user",
                    secondValue: "email",
                },
                {
                    text: "Comment",
                    value: "chat",
                },
            ],
            columnsForPoll: [
                {
                    text: "User Email",
                    value: "user",
                    secondValue: "email",
                },
                {
                    text: "Selected Option",
                    value: "select_option",
                    secondValue: "option",
                },
            ],
            columnsForSurvey: [
                {
                    text: "User Email",
                    value: "email",

                },
            ],
            columnsForSurveyAnswers: [
                {
                    text: "Question",
                    value: "survey_question",
                    secondValue: "question",
                },
                {
                    text: "Select Option",
                    value: "select_option",
                    secondValue: "option",
                },
            ],
            pollResults: [],
            module: "disscusionBoard",
            disscusionId: null,
            isSurveyResult: true,
            loading: false,

        };
    },
    created() {
        this.disscusionId = this.$route.params.id;
        this.fetchDIsscusion(this.disscusionId)
        this.fetch();

    },
    methods: {

        fetch(search="") {
            this.loading = true;
            const id = this.disscusionId;
            this.$store
                .dispatch("disscusionBoard/getChat", { search, id })
                .then((response) => {
                    if (response.data.type == 'survey') {
                        if (response.data.data) {
                            const emails = Object.keys(response.data.data);
                            this.items = emails.map(email => {
                                return {
                                    email: email,
                                    data: response.data.data[email]
                                };
                            });
                            this.pollResults = response.data.percentage;
                        }
                    } else if (response.data.type == 'poll') {
                        this.items = response.data.data;
                        this.pollResults = response.data.percentage;
                        console.log("dttt", this.pollResult);
                    } else {
                        this.items = response.data;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        router.push({ path: "/discussion-board" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
                });
        },

        fetchDIsscusion(id) {
            this.loading = true;
            this.$store
                .dispatch("disscusionBoard/getSingle", { id })
                .then((response) => {
                    this.disscusionBoard = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        handleSurveyResult(email) {
            this.surveyAnswers = [];
            const selectedItem = this.items.find(item => item.email == email);
            if (selectedItem) {
                this.surveyAnswers = selectedItem.data;
                console.log("Data for email " + email + ":", this.surveyAnswers);
            } else {
                console.log("Email not found in items array");
            }
        },
        nextPage(url, search = "") {
            this.loading = true;
            const completeUrl = `${url}&paginate=true&search=${search}`;
            axiosInstance.get(completeUrl).then((response) => {
                this.items = response.data.data;
                console.log("items", this.items);
                this.loading = false;
            });
        },

    },
};
</script>