<template>
    <div>
        <!-- <Breadcrumbs  title="Dashboard" /> -->

        <loading :active="loading" :is-full-page="true" loader="bars"></loading>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Dashboard</h5>

                </div>

            </div>
            <div class="row widget-grid">

                <OverallBalanceCard :data="data" />
                <RecentOrdersCard  :data="data" />
                <ActivitiesCard :data="data" />
                <RecentSalesCard :data="data" />
                <TimelineCard :data="data" />


            </div>
        </div>

    </div>
</template>

<script>
import WelcomeCard from "./default/WelcomeCard.vue";
import PurchaseSaleCard from "./default/PurchaseSaleCard.vue";
import OrdersProfitCard from "./default/OrdersProfitCard.vue";
import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentOrdersCard from "./default/RecentOrdersCard.vue";
import ActivitiesCard from "./default/ActivitiesCard.vue";
import RecentSalesCard from "./default/RecentSalesCard.vue";
import TimelineCard from "./default/TimelineCard.vue";
import ProAccountCard from "./default/ProAccountCard.vue";
import TotalUsersGrowthCard from "./default/TotalUsersGrowthCard.vue";
import PaperNoteCard from "./default/PaperNoteCard.vue";

export default {
    components: {
        WelcomeCard,
        PurchaseSaleCard,
        OrdersProfitCard,
        OverallBalanceCard,
        RecentOrdersCard,
        ActivitiesCard,
        RecentSalesCard,
        TimelineCard,
        ProAccountCard,
        TotalUsersGrowthCard,
        PaperNoteCard,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "Title",
                    value: "title",
                },
                {
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Status",
                    value: "status_text",
                },
                {
                    text: "Date",
                    value: "datecreate",
                },
            ],
            data: [],
            module: "news",
            permissions: null,
            isEdit: false,
            isDelete: false,
            loading: false,

        };
    },

    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("dashboard/get")
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>
