<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Roles</h5>
            <router-link v-if="permissions && permissions.includes('role-Add')" to="/roles/add"
              class="btn btn-sm btn-primary mb-2 float-end">Add Role</router-link>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :items="items" @deleted="fetch" :isEdit="isEdit"
              :isDelete="isDelete" @nextPage="nextPage" @clear="fetch" @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Name",
          value: "name",
        },
      ],
      module: "roles",
      permissions: null,
      isEdit: false,
      isDelete: false,
      loading: false,
    };
  },
  created() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("role-Edit")) ? true : false;
    this.isDelete = (this.permissions && this.permissions.includes("role-Delete")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("roles/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
