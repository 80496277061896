import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    delete({ dispatch, commit }, { id }) {
        return new Promise((resolve, reject) => {
          axiosInstance
            .delete(`/news/${id}`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e.response.data);
            });
        });
      },
  },
};
