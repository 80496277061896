<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/active-citizen" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit Active Citizen</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addTeam">

                            <label>User Email</label>
                            <select class="custom-select mb-3" required v-model="citizen.user_id" :disabled="true" readonly>
                                <option value="" selected>Select User</option>
                                <option v-for="t in users" :value="t.id" :key="t.id">{{ t.email }}</option>
                            </select>

                            <!-- <label>Media</label><br />
                            <input ref="fileInput" type="file" accept="image/*,video/*" class="form-control"
                                @change="handleImageUpload" />
                            <br /> -->

                            <label>Short Detail</label>
                            <QuillEditor v-model="citizen.detail" :editorHeight="'300px'" :editorWidth="'800px'"
                                required />

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
    components: {
    QuillEditor,
  },
    data() {
        return {
            citizen: {
                image: null,
            },
            citizenId: null,
            loading: false,
            modules: [],
            users: [],
        };
    },
    mounted() {
        this.citizenId = this.$route.params.id;
        this.fetch(this.citizenId);
        this.fetchUsers();
    },
    methods: {
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.citizen.image = e.target.result;
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("activeCitizen/getSingle", { id })
                .then((response) => {
                    this.citizen = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        router.push({ path: "/active-citizen" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.citizen);
            const id = this.citizenId;
            this.$store
                .dispatch("activeCitizen/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.users = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },


};
</script>

