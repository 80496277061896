<template>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5">
          <div class="card">
          
            <div class="card-body">
              <p class="text-center">This page is currently in production and will be live soon. Thank you for your patience!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style>
    .container {
      margin-top: 50px;
    }
  
    .card {
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  
    .card-header {
      background-color: #007bff;
      color: white;
      border-radius: 10px 10px 0 0;
    }
  
    .card-body {
      padding: 20px;
    }
  </style>
  