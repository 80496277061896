<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/districts" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit District</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                         
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="district.name" />

                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            district: {
                name: "",
            },
            countries: [],
            provinces: [],
            districtId: null,
            loading: false,
        };
    },
    mounted() {
        this.districtId = this.$route.params.id;
        this.fetch(this.districtId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("districts/getSingle", { id })
                .then((response) => {
                    this.district = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.district);
            const id = this.districtId;
            this.$store
                .dispatch("districts/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>