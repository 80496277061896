import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/social-campaign${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getRegisterUserCampaign({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const id=query.id;
        const queryString = query
        ? `?${Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
        axiosInstance
        .get(`/campaign-registered-users/${id}${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/social-campaign/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    store({ dispatch, commit }, { data }) {
      console.log("data updated", data);
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/social-campaign", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    update({ dispatch, commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`/social-campaign/${id}`, data)
          .then((response) => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    delete({ dispatch, commit }, { id }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`/social-campaign/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getTmaCampaigns({ dispatch, commit },{id}) {
      return new Promise((resolve, reject) => {
        axiosInstance
        .get(`/get-campaigns-tma/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
