<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-12 mt-5">
  
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">Support</h5>
            </div>
            <div class="card-body">
              <data-table :columns="columns"  :module="module" 
                :items="items" @deleted="fetch" @nextPage="nextPage" @clear="fetch" :supportEmail="true" @search="fetch"></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DataTable from "@/components/dataTable.vue";
  import axiosInstance from "@/helpers/axios";
  
  
  
  export default {
    components: {
      DataTable,
    },
    data() {
      return {
        items: [],
        columns: [
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Message",
            value: "message",
          },
  
        ],
        module: "support",
        permissions: null,
        isViewAble: true,
        isEdit: false,
        loading: false,
        isblockUser: false,
      };
    },
    mounted() {
      this.fetch();
      this.permissions = localStorage.getItem("permissions");
    },
    methods: {
      fetch(search = "") {
        this.loading = true;
        this.$store
          .dispatch("aboutus/getAllSupport", { search, paginate: true })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      },
      nextPage(url, search = "") {
        this.loading = true;
        const completeUrl = `${url}&paginate=true&search=${search}`;
        axiosInstance.get(completeUrl).then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
      },
    },
  };
  </script>