<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/contact-us" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Contact us</h4>
                    </div>
                    <div class="card-body">

                        <div class="admin-container">

                            <div class="contact-data" id="contactData">
                                <!-- Static data displayed here -->
                                <div class="contact-item">
                                    <strong>Name:</strong> {{ contact?.name }}
                                </div>
                                <div class="contact-item">
                                    <strong>Email:</strong> {{ contact.email }}
                                </div>
                                <div class="contact-item">
                                    <strong>Phone:</strong> {{ contact.phone_number }}
                                </div>
                                <div class="contact-item">
                                    <strong>Message:</strong> {{ contact.message }}
                                </div>
                                <div class="contact-item" v-if="contact?.file?.image_url">
                                    <strong>File:</strong>
                                    <template v-if="contact?.file?.image_url">
                                        <a :href="contact.file.image_url" target="_blank" rel="noopener noreferrer">
                                            Open File
                                        </a>
                                    </template>
                                    <template v-else>
                                        No file available
                                    </template>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            id: "",
            contact: [],
            departmentId: null,
            loading: false,
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetch(this.id);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("aboutus/getSingle", { id })
                .then((response) => {
                    this.contact = response.data;
                    console.log("ss", this.contact);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        }
    },

};
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 20px;
}

.admin-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-data {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.contact-item strong {
    display: block;
    margin-bottom: 5px;
}
</style>