<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">

        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Polls</h5>
            <router-link v-if="permissions && permissions.includes('poll-Add')" to="/poll/add"
              class="btn btn-sm btn-primary mb-2 float-end">Add
            </router-link>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :isDisscussion="isDisscussion" :isEdit="isEdit"
              :isDelete="isDelete" :items="items" @deleted="fetch" @nextPage="nextPage" @clear="fetch"
              @search="fetch"></data-table>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Title",
          value: "title",
        },

        {
          text: "Start Date",
          value: "format_start_date",
        },
        {
          text: "End Date",
          value: "format_end_date",
        },
        {
          text: "Created at",
          value: "created_at",
        },
      ],
      module: "poll",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isDisscussion: true,
      loading:false
    };
  },
  created() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("poll-Edit")) ? true : false;
    this.isDelete = (this.permissions && this.permissions.includes("poll-Delete")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("disscusionBoard/getAll", { search, paginate: true, type: "poll" })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}&type=poll`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>