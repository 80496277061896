<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">

        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">Admins</h5>
            <router-link to="/admins/add" v-if="permissions && permissions.includes('admin-Add')"
              class="btn btn-sm btn-primary mb-2 float-end">Add Admin</router-link>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :items="items" :isEdit="isEdit" @blockAdmin="blockAdmin"
              :isChangePassword="changePassword" :adminBlock="adminBlock" @deleted="fetch" @nextPage="nextPage"
              @clear="fetch" @search="fetch"></data-table>
          </div>
        </div>

      </div>
      <a href="#" class="d-none" id="blockUserBtn" data-bs-toggle="modal"
        data-bs-target="#totalParticipantModal">Block</a>
    </div>
    <div class="modal fade" id="totalParticipantModal" tabindex="-1" aria-labelledby="totalParticipantModalLabel"
      aria-hidden="true">
      <form @submit.prevent="confirmBlockAdmin">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p class="fw-bold">Are you want to {{ adminAccount.isBlock == 0 ? 'block' : 'unblock' }} {{
                adminAccount?.f_name
                ?? "" }}?</p>
              <div v-if="adminAccount.isBlock == 0">
                <label class="fw-bold" for="blockReason">Reason for blocking:</label>
                <textarea id="blockReason" v-model="blockReason" class="form-control"
                  placeholder="Provide a reason for blocking this admin" rows="4" required>
        </textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-danger" id="cancelBlockAdminModal" data-bs-dismiss="modal">
                No
              </button>
              <button type="submit" class="btn btn-sm btn-success"
                :data-bs-dismiss="adminAccount.isBlock == 1 ? 'modal' : null">
                Yes
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "f_name",
        },
        {
          text: "Email",
          value: "email",
        },

      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: false,
      permissions: null,
      isEdit: false,
      isDelete: false,
      adminBlock: true,
      adminAccount: "",
      blockReason: "",
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("admin-Edit")) ? true : false;
    this.isDelete = (this.permissions && this.permissions.includes("admin-Delete")) ? true : false;
    this.changePassword = (this.permissions && this.permissions.includes("admin-changePassword")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAll", { search, id, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },

    blockAdmin(admin) {
      this.adminAccount = admin;
      this.blockReason = "";
      const btn = document.getElementById("blockUserBtn");
      btn.click();
    },
    confirmBlockAdmin() {
      const id = this.adminAccount.id;
      const data = {
        'reason': this.blockReason
      };
      this.loading = true;
      this.$store
        .dispatch("admins/blockUnblockAdmin", { data, id })
        .then((response) => {
          this.loading = false;
          const btn = document.getElementById("cancelBlockAdminModal");
          btn.click();
          this.$toast.show("Successfully Updated", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.fetch();
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to update", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    }


  },
};
</script>
