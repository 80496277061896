<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/admins" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Admin</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addUser">
              <div class="form-group row">
                <div class="col-md-6">
                  <label>First Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    required
                    v-model="admin.fName"
                  />
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    v-model="admin.lName"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  class="form-control form-control-sm mb-3"
                  v-model="admin.phone"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.email">
                  Email is required
                </span>
                <input
                  type="email"
                  class="form-control form-control-sm mb-3"
                  required
                  v-model="admin.email"
                />
              </div>
              <div class="form-group">
                <label>Password <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.password">
                  Password is required
                </span>
                <input
                  type="password"
                  class="form-control form-control-sm mb-3"
                  required
                  v-model="admin.password"
                />
              </div>
              <div class="form-group">
                <label>Role <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  v-model="admin.role"
                  required
                >
                  <option value="">Select...</option>
                  <option v-for="role in roles" :value="role.name">
                    {{ role.name }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      admin: {
        fName: null,
        lName: null,
        phone: null,
        email: null,
        password: null,
        role: "",
        createdBy: localStorage.getItem("uid"),
      },
      roles: [],
      errors: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const id = localStorage.getItem("uid");
      const data = toRaw(this.admin);
      data.parent_id=id;
      data.createdBy=id;
      this.$store
        .dispatch("admins/store", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$refs.addUser.reset();
          Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    fetchRoles() {
      this.loading = true;
      this.$store
        .dispatch("roles/getAll")
        .then((response) => {
          this.roles = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
