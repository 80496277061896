<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/area" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Tehsil</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">

                            <div class="form-group">
                                <label>District <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" @change="districtChange" v-model="area.district_id"
                                    required>
                                    <option value="">Select...</option>
                                    <option v-for="district in districts" :value="district.id">
                                        {{ district.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Sub Division <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" v-model="area.tehsil_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="tehsil in tehsils" :value="tehsil.id">
                                        {{ tehsil.name }}
                                    </option>
                                </select>
                            </div>
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required v-model="area.name" />
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            area: {
                name: "",
                district_id: "",
                province_id: "",
                tehsil_id: "",
                location: ""
            },
            districts: [],
            countries: [],
            provinces: [],
            tehsils: [],
            loading: false,
        };
    },
    mounted() {
        this.fetchAllDistricts();
    },
    methods: {
        submit() {
            this.loading = true;
            const data = toRaw(this.area);
            this.$store
                .dispatch("area/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.area).forEach((key) => (this.area[key] = ""));
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        fetchAllDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        districtChange() {
            this.area.tehsil_id = "";
            const id = this.area.district_id;
            this.loading = true;
            this.$store
                .dispatch("districts/getAllTehsilByDistrict", { id })
                .then((response) => {
                    this.tehsils = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        }

    },
};
</script>