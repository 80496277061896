<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/press" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Press Release</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="news.title"
              />
              <!-- New input for description -->
              <label>Date & Time<span class="text-danger">*</span></label>
              <input
                type="datetime-local"
                class="form-control form-control-sm mb-3"
                v-model="news.date"
                required
              />
              <div v-if="news.type === 'Event'">
                <label>Location<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm mb-3"
                  v-model="news.location"
                  required
                />
              </div>
              <div class="d-flex align-items-center mt-3">
                <div class="mb-3 d-flex align-items-center">
                  <label class="me-3">Status</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="registervolunteer"
                      v-model="news.status"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex align-items-center ms-4">
                  <label class="me-3">Send Notification</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="sendNotificationYes"
                      @change="handleNotificationChange"
                      v-model="news.notification"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
              </div>
              <label>Upload Image<span class="text-danger">*</span></label
              ><br />
              <input
                ref="video"
                type="file"
                accept="image/*"
                class="form-control"
                @change="handleFileUpload"
                required
              />

              <img
                v-if="mediaType === 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100"
              />

              <video
                class="mt-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'video'"
              />

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div>
                <label class="mt-2">PDF Document</label>
                <input
                  ref="fileInput"
                  @change="uploadPDF"
                  type="file"
                  accept="application/pdf"
                  class="form-control"
                />
              </div>

              <div v-if="pdfUrl" class="mt-2">
                <a
                  class="mt-2 link-with-underline"
                  v-if="pdfUrl"
                  :href="pdfUrl"
                  target="_blank"
                  >View PDF</a
                >
              </div>
              <label class="mt-3">Detail</label>
              <QuillEditor
                v-model="news.detail"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="col-md-12">
                    <button
                      type="submit"
                      :disabled="uploadProgress > 0"
                      class="btn btn-sm btn-primary"
                    >
                      {{
                        uploadProgress > 0
                          ? "Please wait, file uploading..."
                          : "Save"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  mixins: [helpers],
  data() {
    return {
      files: [],
      news: {
        image: null,
        status: 0,
        notification: 0,
        type: "",
        date: null,
        detail: "",
        location: "",
        document: "",
        notificationFilter: [],
      },
      loading: false,
      modules: [],
      imagePreview: null,
      pdfPreview: null,
      mediaType: "",
      pdfUrl: "",
      uploadProgress: -1,
    };
  },

  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Press",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.news.image = filePath;
        this.uploadProgress = -1;
      }
    },
    uploadPDF(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = btoa(
          new Uint8Array(reader.result).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        this.news.document = `data:application/pdf;base64,${base64String}`;
        const blob = new Blob([reader.result], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      };
      reader.readAsArrayBuffer(file);
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.news);
      data.type = "Resolution";
      this.$store
        .dispatch("news/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.news).forEach((key) => (this.news[key] = null));
            this.$refs.video.value = "";
            this.$refs.fileInput.value = "";
            this.news.status = 0;
            this.news.notification = 0;
            this.mediaType = "";
            this.pdfUrl = "";
            this.uploadProgress = -1;
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.uploadProgress = -1;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.uploadProgress = -1;
        });
    },
    handleNotificationChange() {
      if (this.news.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.news.notificationFilter = result;
    },
  },
};
</script>