<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/discussion-board" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Disscusion Board</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addTeam">
                            <label>Title <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="disscusionBoard.title" />
                            <label>Start Date <span class="text-danger">*</span></label>
                            <input type="date" v-model="disscusionBoard.start_date"
                                class="form-control form-control-sm mb-3" required />
                            <label>End Date <span class="text-danger">*</span></label>
                            <input type="date" v-model="disscusionBoard.end_date"
                                class="form-control form-control-sm mb-3" required />
                            <div class="mb-3 d-flex align-items-center">
                                <label class="me-3">Send Notification</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="sendNotificationYes"
                                        v-model="disscusionBoard.notification" :true-value="1" :false-value="0"
                                        @change="handleNotificationChange" />
                                </div>
                            </div>
                            <!-- <label>Type<span class="text-danger">*</span></label>
                            <div class="mb-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="typedisscusionBoard"
                                        name="typeDisscussion" v-model="disscusionBoard.type" value="chat" required>
                                    <label class="form-check-label" for="typedisscusionBoard">Open Chat</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="typeEvent" name="typeDisscussion"
                                        v-model="disscusionBoard.type" value="survey" required>
                                    <label class="form-check-label" for="typeEvent">Survey</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="typeResolutaion"
                                        name="typeDisscussion" v-model="disscusionBoard.type" value="poll" required>
                                    <label class="form-check-label" for="typeResolutaion">Poll</label>
                                </div>
                            </div> -->

                            <div class="mb-2" v-if="disscusionBoard.type === 'chat'">
                                <label>Chat Topic</label>
                                <QuillEditor v-model="disscusionBoard.message" :editorHeight="'300px'"
                                    :editorWidth="'800px'" required />
                            </div>



                            <div class="mb-2" v-if="disscusionBoard.type === 'survey'">
                                <template v-for="(question, questionIndex) in disscusionBoard.survey"
                                    :key="questionIndex">
                                    <label>Question {{ questionIndex + 1 }}</label>
                                    <textarea class="form-control form-control-sm mb-3" rows="2"
                                        v-model="question.question" required></textarea>

                                    <div v-for="(option, optionIndex) in question.options" :key="optionIndex">
                                        <div class="d-flex justify-content-end mt-3">
                                            <button type="button" class="btn btn-sm btn-danger"
                                                @click="removeOptionSurvey(question, optionIndex)">
                                                Remove
                                            </button>
                                        </div>
                                        <label>Option {{ optionIndex + 1 }}</label>
                                        <input class="form-control form-control-sm" v-model="option.option" required>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <button @click="addSurveyOption(question, $event)"
                                                class="btn btn-sm btn-primary">
                                                Add Option
                                            </button>
                                        </div>
                                    </div>

                                    <hr>
                                </template>

                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <button @click="addSurveyQuestion" class="btn btn-sm btn-primary">
                                            Add Question
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <div class="mb-2" v-if="disscusionBoard.type === 'poll'">
                                <label>Title</label>
                                <textarea class="form-control form-control-sm mb-3" rows="2"
                                    v-model="disscusionBoard.poll.question" required></textarea>

                                <div class="row">
                                    <template v-for="(obj, index) in disscusionBoard.poll.options" :key="index">
                                        <div class="d-flex justify-content-end mt-3">
                                            <button type="button" class="btn btn-sm btn-danger"
                                                @click="removeObjective(index)">
                                                Remove
                                            </button>
                                        </div>
                                        <label>Option {{ index + 1 }}</label>
                                        <div>
                                            <input class="form-control form-control-sm"
                                                v-model="disscusionBoard.poll.options[index].option" required>
                                        </div>

                                    </template>

                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <button @click="addPollOptions" class="btn btn-sm btn-primary">
                                            Add Option
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" id="openModel" class="d-none" data-bs-toggle="modal" data-bs-target="#notificationModal">
            Open Modal
        </button>

        <notification_filter @save="filterResult"></notification_filter>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import notification from "@/store/modules/notification";
export default {
    components: {
        QuillEditor,
        notification_filter
    },
    data() {
        return {
            files: [],
            disscusionBoard: {
                title: null,
                message: null,
                type: "chat",
                start_date: null,
                end_date: null,
                image: null,
                notification: 0,
                poll: {
                    question: [],
                    options: [{ option: "" }]
                },
                survey: [{
                    question: "",
                    options: [{ option: "" }]
                }],
                notificationFilter: [],
            },
            pollOptions: {
                option: ""
            },
            surveyDissucssion: {
                question: "",
                options: [{
                    option: ""
                }]
            },
            loading: false,
            modules: [],
            imagePreview: null,
        };
    },

    methods: {
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.disscusionBoard.image = e.target.result;
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        addSurveyQuestion(e) {
            e.preventDefault();
            this.disscusionBoard.survey.push({ question: this.surveyDissucssion.question, options: [{ option: "" }] });
            this.surveyDissucssion.question = "";
        },
        addSurveyOption(question, event) {
            event.preventDefault();
            question.options.push({ option: "" });
        },
        addPollOptions(e) {
            e.preventDefault();
            this.disscusionBoard.poll.options.push({ option: this.pollOptions.option });
            this.pollOptions.option = "";
        },
        removeObjective(index) {
            this.disscusionBoard.poll.options.splice(index, 1);
        },
        removeOptionSurvey(question, index) {
            question.options.splice(index, 1);
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.disscusionBoard);
            this.$store
                .dispatch("disscusionBoard/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.disscusionBoard).forEach((key) => {
                            this.disscusionBoard[key] = null;
                        });
                        this.disscusionBoard.notification = 0;
                        this.disscusionBoard.poll = {
                            question: [],
                            options: []
                        };
                        this.disscusionBoard.survey = [{
                            question: "",
                            options: [{ option: "" }]
                        }];
                        this.imagePreview = null;
                        this.disscusionBoard.type = "chat";
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        handleNotificationChange() {
            if (this.disscusionBoard.notification == 1) {
                const btn = document.getElementById("openModel");
                btn.click();
            }
        },
        filterResult(result) {
            this.disscusionBoard.notificationFilter = result;
        }
    },
};
</script>