<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="col-xl-12 col-md-12 box-col-12">
                    <div class="file-content">
                        <div class="card">
                            <div class="card-header">
                                <div class="media">
                                    <form class="form-inline" action="#" method="get">
                                        <div class="form-group mb-0">
                                            <i class="fa fa-search"></i>
                                            <input class="form-control-plaintext" type="text" placeholder="Search..."
                                                v-model="searchQuery" />
                                        </div>
                                    </form>
                                    <div class="media-body text-end">
                                        <form class="d-inline-flex" action="#" method="POST"
                                            enctype="multipart/form-data" name="myForm">
                                            <button v-if="permissions && permissions.includes('gallary-Add')"
                                                type="button" class="btn btn-sm btn-primary" @click="addNewFolder">
                                                <vue-feather type="plus-square" class="text-top"></vue-feather>
                                                Add New
                                            </button>
                                            <div style="height: 0px; width: 0px; overflow: hidden">
                                                <input id="upfile" type="file" onchange="sub(this)" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body file-manager">
                                <h6 class="mt-2">Folders</h6>
                                <div v-if="filteredFolders.length === 0" class="text-center">
                                    <p>No folders exist.</p>
                                </div>
                                <ul class="folder">
                                    <li class="folder-box mb-3" v-for="(folder, index) in filteredFolders " :key="index"
                                        @click="redirectGallery(folder)">
                                        <div class="media">
                                            <i class="fa fa-folder f-36 txt-warning"></i>
                                            <div
                                                class="media-body ms-3 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div v-if="editingFolderIndex === index" @click.stop>
                                                        <input v-model="folder.name" @change="stopEditing"
                                                            class="form-control" />
                                                    </div>
                                                    <div v-else>
                                                        <h6 class="mb-0">{{ folder.name }}</h6>
                                                        <p>{{ folder.size }}</p>
                                                    </div>
                                                </div>
                                                <div class="dropdown">
                                                    <button @click.stop="toggleMenu(index)" class="btn btn-link p-0"
                                                        style="text-decoration: none;">
                                                        <i class="fa fa-ellipsis-v"></i>
                                                    </button>
                                                    <div v-if="isMenuOpen(index)" class="dropdown-menu show">
                                                        <a @click.stop="editFolder(index)"
                                                            class="dropdown-item">Edit</a>
                                                        <a @click.stop="deleteFolder(index, folder.id)"
                                                            class="dropdown-item">Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            folders: [],
            editingFolderIndex: null,
            menuIndex: null,
            editingFolderName: '',
            errorMessage: '',
            loading: false,
            searchQuery: '',
            permissions: [],
        };
    },
    created() {
        this.fetch();
        this.permissions = localStorage.getItem("permissions");
    },
    computed: {
        filteredFolders() {
            return this.folders.filter(folder =>
                folder.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("gallary/getAll")
                .then((response) => {
                    this.folders = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        addNewFolder() {
            this.editingFolderIndex = null;
            const baseName = "New Folder";
            let index = 1;
            let newName = `${baseName}`;
            while (this.folders.some(folder => folder.name === newName)) {
                index++;
                newName = `${baseName} ${index}`;
            }
            this.folders.unshift({
                name: newName,
                size: "0 files, 0mb"
            });
            const data = {
                name: newName
            };
            this.loading = true;
            this.$store
                .dispatch("gallary/store", { data })
                .then((response) => {
                    this.$nextTick(() => {
                        this.folders[0] = response.data;
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        startEditing(index, folder) {
            this.editingFolderIndex = index;
            this.editingFolderName = folder;
            this.errorMessage = '';
        },
        stopEditing() {
            if (this.isDuplicateFolderName(this.editingFolderName.name)) {
                alert("Folder already exists. Please choose a different name.")
                return;
            }
            this.folders[this.editingFolderIndex].name = this.editingFolderName.name;
            const data = this.editingFolderName;
            this.loading = true
            const id = this.editingFolderName.id;
            this.$store
                .dispatch("gallary/update", { data, id })
                .then((response) => {
                    this.loading = false;

                })
                .catch((e) => {
                    this.loading = false;
                });
            this.editingFolderIndex = null;
            this.editingFolderName = '';
            this.errorMessage = '';
        },
        isDuplicateFolderName(name) {
            return this.folders.some(folder => folder.name === name && folder !== this.folders[this.editingFolderIndex]);
        },
        redirectGallery(folder) {
            console.log("editingFolderIndex", this.editingFolderIndex);
            if (!this.editingFolderIndex) {
                this.$router.push(`/gallary-media/${folder.encrypted_id}`);
            }
        },
        toggleMenu(index) {
            console.log("index", index);
            this.menuIndex = this.menuIndex === index ? null : index;
        },
        isMenuOpen(index) {
            return this.menuIndex === index;
        },
        editFolder(index) {
            this.editingFolderIndex = index;
            this.startEditing(index, this.folders[index]);
            this.menuIndex = null;
        },
        deleteFolder(index, id) {
            this.folders.splice(index, 1);
            this.menuIndex = null;
            this.loading = true;
            this.$store
                .dispatch("gallary/delete", { id })
                .then((response) => {
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },

    },
};
</script>

<style>
.media .dropdown-menu {
    z-index: 10000 !important;

}

.folder-box {
    position: static !important;
}


</style>