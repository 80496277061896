<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
          
      <div class="col-md-12 mt-5">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">All Complaints</h5>
            <!-- <router-link to="/complaint/add" class="btn btn-sm btn-primary mb-2 float-end">Add Complain</router-link> -->

          </div>
          <div class="card-body">
            <data-table :columns="columns" :isDelete="isDelete" :module="module" :isViewAble="isViewAble" :items="items"
              @deleted="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "User Name",
          value: "user_profile",
          secondValue: "name",
        },
        {
          text: "Complaint Type",
          value: "complaint_type",
          secondValue: "name",
        },
      ],
      module: "complaints",
      isViewAble: true,
      isDelete:true
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("complaints/getAll")
        .then((response) => {
          this.items = response.data;
          console.log("complaints", this.items);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
