export var menuItems = {
  data: [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
      public: true,
    },
    {
      path: "/tma",
      title: "TMA",
      icon: "stroke-project",
      iconf: "fill-project",
      type: "link",
      active: false,
      module: "tma-admins",
    },
    {
      path: "/tma_events",
      title: "Events",
      icon: "stroke-calendar",
      iconf: "fill-calendar",
      type: "link",
      active: false,
      module: "event_tma",
    },
    {
      title: "Setting",
      icon: "stroke-gallery",
      iconf: "fill-gallery",
      type: "sub",
      active: false,
      module: ["setting"],
      children: [
        {
          path: "/professions",
          title: "Professions",
          type: "link",
          active: false,
          module: "setting",
        },
        {
          path: "/districts",
          title: "Districts",
          type: "link",
          active: false,
          module: "setting",
        },
        {
          path: "/tehsil",
          title: "Sub Division",
          type: "link",
          active: false,
          module: "setting",
        },
        {
          path: "/area",
          title: "Tehsil",
          type: "link",
          active: false,
          module: "setting",
        },
      ],
    },

    {
      path: "/web-content/add",
      title: "Web Content",
      icon: "stroke-to-do",
      iconf: "fill-to-do",
      type: "link",
      active: false,
      module: "web-content",
    },

    {
      path: "/gallary",
      title: "Gallary",
      icon: "stroke-file",
      iconf: "fill-file",
      type: "link",
      active: false,
      module: "gallary-View",
    },

    {
      title: "About TMA",
      icon: "stroke-gallery",
      iconf: "fill-gallery",
      type: "sub",
      active: false,
      module: ["tmaVideos-View", "tmaTeam-View"],
      children: [
        {
          path: "/about-tma-videos",
          title: "Videos",
          type: "link",
          active: false,
          module: "tmaVideos-View",
        },
        {
          path: "/managment-team",
          title: "Managment Team",
          type: "link",
          active: false,
          module: "tmaTeam-View",
        },
      ],
    },

    // {
    //   path: "/projects",
    //   title: "Projects",
    //   icon: "stroke-project",
    //   iconf: "fill-project",
    //   type: "link",
    //   active: false,
    //   module: "project-View",
    // },
    {
      path: "/news",
      title: "News",
      icon: "stroke-table",
      iconf: "fill-table",
      type: "link",
      active: false,
      module: "newsAndEvent-View",
    },
    {
      path: "/events",
      title: "Events",
      icon: "stroke-calendar",
      iconf: "fill-calendar",
      type: "link",
      active: false,
      module: "event-View",
    },
    {
      path: "/press",
      title: "Press Release",
      icon: "stroke-job-search",
      iconf: "fill-job-search",
      type: "link",
      active: false,
      module: "press-View",
    },

    {
      path: "/discussion-board",
      title: "Discussion Board",
      icon: "stroke-chat",
      iconf: "fill-chat",
      type: "link",
      active: false,
      module: "disscussionBoard-View",
    },

    {
      path: "/poll",
      title: "Polls",
      icon: "stroke-charts",
      iconf: "fill-charts",
      type: "link",
      active: false,
      module: "poll-View",
    },

    {
      path: "/survey",
      title: "Survey",
      icon: "stroke-blog",
      iconf: "fill-blog",
      type: "link",
      active: false,
      module: "survey-View",
    },

    {
      title: "Campaigns",
      icon: "stroke-social",
      iconf: "fill-social",
      type: "sub",
      active: false,
      module: ["campaign-View", "campaignType-View"],
      children: [
        {
          path: "/campaign-types",
          title: "Types",
          type: "link",
          active: false,
          module: "campaignType-View",
        },
        {
          path: "/campaigns",
          title: "Campaigns",
          type: "link",
          active: false,
          module: "campaign-View",
        },
      ],
    },
    // {
    //   title: "Complain Managment",
    //   icon: "stroke-email",
    //   iconf: "fill-email",
    //   type: "sub",
    //   active: false,
    //   module: ["complainType-View", "complain-View"],
    //   children: [
    //     {
    //       path: "/complaint-types",
    //       title: "Types",
    //       type: "link",
    //       active: false,
    //       module: "complainType-View",
    //     },
    //     {
    //       path: "/complaints",
    //       title: "Complaints",
    //       type: "link",
    //       active: false,
    //       module: "complain-View",
    //     },
    //   ],
    // },

    // {
    //   title: "Collaborate with TMA",
    //   icon: "stroke-task",
    //   iconf: "fill-task",
    //   type: "sub",
    //   active: false,
    //   module: ["serviceType-View", "collaboration-View"],
    //   children: [
    //     {
    //       path: "/service-types",
    //       title: "Types",
    //       type: "link",
    //       active: false,
    //       module: "serviceType-View",
    //     },
    //     {
    //       path: "/invest-with-tma",
    //       title: "Collaborate with TMA",
    //       type: "link",
    //       active: false,
    //       module: "collaboration-View",
    //     },
    //   ],
    // },

    {
      title: "Active Citizen",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ["successStory-View", "activeCitizen-View"],
      children: [
        {
          path: "/active-citizen",
          title: "Active Citizen",
          type: "link",
          active: false,
          module: "activeCitizen-View",
        },
        {
          path: "/success-stories",
          title: "Success Stories",
          type: "link",
          active: false,
          module: "successStory-View",
        },
      ],
    },

    {
      path: "/register-with-tma",
      title: "Register With TMA",
      icon: "stroke-editors",
      iconf: "fill-editors",
      type: "link",
      active: false,
      module: "registerWithTma-View",
    },
    // {
    //   path: "/partnerwithtma",
    //   title: "Partner With TMA",
    //   icon: "stroke-task",
    //   iconf: "fill-task",
    //   type: "link",
    //   active: false,
    //   module: "partnershipwithtma",
    // },

    {
      title: "Partner With TMA",
      icon: "stroke-task",
      iconf: "fill-task",
      type: "sub",
      active: false,
      module: ["serviceType-View", "partnershipwithtma"],
      children: [
        {
          path: "/service-types",
          title: "Service Types",
          type: "link",
          active: false,
          module: "serviceType-View",
        },
        {
          path: "/partnerwithtma",
          title: "Partnership Requests",
          type: "link",
          active: false,
          module: "partnershipwithtma",
        },
      ],
    },
    {
      path: "/app-users",
      title: "App Users",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "link",
      active: false,
      module: "appUsers-View",
    },
    {
      path: "/contact-us",
      title: "Contact us",
      icon: "stroke-builders",
      iconf: "fill-builders",
      type: "link",
      active: false,
      module: "aboutUs-View",
    },
    // {
    //   path: "/advance-search",
    //   title: "Advance Search",
    //   icon: "stroke-job-search",
    //   iconf: "fill-job-search",
    //   type: "link",
    //   active: false,
    //   module: "advanceSearch-View",
    // },
    {
      path: "/reporting",
      title: "Reporting",
      icon: "stroke-job-search",
      iconf: "fill-job-search",
      type: "link",
      active: false,
      module: "reporting",
    },
    {
      title: "Administration",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      module: ["role-View", "admin-View"],
      children: [
        {
          path: "/admins",
          title: "Admins",
          type: "link",
          active: false,
          module: "admin-View",
        },
        {
          path: "/roles",
          title: "Roles",
          type: "link",
          active: false,
          module: "role-View",
        },
      ],
    },
    {
      path: "/user/feedback",
      title: "Feedback",
      icon: "stroke-bookmark",
      iconf: "stroke-bookmark",
      type: "link",
      active: false,
      module: "feedback",
    },
    {
      path: "/support",
      title: "Support",
      icon: "stroke-support-tickets",
      iconf: "stroke-support-tickets",
      type: "link",
      active: false,
      module: "support",
    },
    {
      path: "/notifications",
      title: "Notifications",
      icon: "notification",
      iconf: "notification",
      type: "link",
      active: false,
      module: "notification-View",
    },
  ],
};
